import convertNode from "../convertNode";

export default function parseList(xmlNode) {
  const content = Array.from(xmlNode.children).map(convertNode);
  const isEmpty = !content || content.length === 0 || content.every((item) => !item);
  if (isEmpty) {
    return null;
  }

  return {
    type: "ordered_list",
    attrs: {
      id: xmlNode.getAttribute("id"),
      start: xmlNode.getAttribute("start"),
      type: xmlNode.getAttribute("type"),
      class: xmlNode.getAttribute("class"),
    },
    content,
  };
}
