const createListItem = () => ({
  content: "(paragraph | container)+ block*",
  defining: true,
  parseDOM: [{ tag: "li" }],
  toDOM(node) {
    return [
      "li",
      {
        "data-id": node.attrs.id || null,
      },
      0,
    ];
  },
  attrs: {
    id: { default: null },
  },
});

export default createListItem;
