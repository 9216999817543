<script setup>
import formatDate from "@/utils/formatDate";
import { EditorView } from "prosemirror-view";
import { EditorState } from "prosemirror-state";
import { Node } from "prosemirror-model";
import defaultSchema from "@/components/ProseMirror/schemas/defaultSchema";
import { useStore } from "vuex";
import axios from "axios";
import HtmlDiff from "htmldiff-js";
import { useIsMobile } from "@/utils/checkMobile";
import scrollToElement from "@/utils/scrollToElement";

// Component atributes
const emit = defineEmits(["close", "restoreVersion"]);
const props = defineProps({
  isHistoryOpen: { type: Boolean },
  documentId: { type: String },
  withRestore: { type: Boolean, default: true },
});

// HTML Refs
const previousEditorRef = ref(null);
const previousContentRef = ref(null);
const previousPreviewRef = ref(null);
const currentEditorRef = ref(null);
const currentContentRef = ref(null);
const currentPreviewRef = ref(null);

// Variables
const mySchema = defaultSchema;
const isMobile = useIsMobile();
const store = useStore();
const historyIndex = ref(0);
const history = ref([]);
const previousContent = ref();
const currentContent = ref();

let previousEditorView = null;
let currentEditorView = null;

// Load editor functions
const fetchDocumentHistory = async () => {
  const response = await axios.get(`api/documents/${props.documentId}/history`);
  history.value = response.data.data;

  const historyLength = history.value.length;

  historyIndex.value = historyLength < 1 ? 0 : historyLength - 1;
  const prevIndex = historyLength < 2 ? 0 : historyLength - 2;

  const currentVersion = history.value[historyLength - 1];
  const prevVersion = history.value[prevIndex];

  currentContent.value = currentVersion;
  previousContent.value = prevVersion;
};

const getEditorContent = () => ({
  previousState: JSON.parse(previousContent.value.snapshot),
  newState: JSON.parse(currentContent.value.snapshot),
});

const renderEditorContent = (htmlElement, content) => {
  const isPrevious = htmlElement.id === "previousEditor";
  const doc = Node.fromJSON(mySchema, toRaw(content));

  const newEditor = new EditorView(htmlElement, {
    state: EditorState.create({
      doc: doc,
    }),
    editable: () => false,
  });

  if (isPrevious) {
    previousEditorView = newEditor;
  } else {
    currentEditorView = newEditor;
  }
};

const updateEditorContent = (editorView, content, isNode = false) => {
  const doc = isNode ? content : Node.fromJSON(mySchema, toRaw(content));

  editorView.updateState(
    EditorState.create({
      doc: doc,
    }),
  );
};

const scrollToChanges = () => {
  const diffInPrevView = currentPreviewRef.value.querySelector(".diffmod");

  currentPreviewRef.value.parentElement.onscroll = (e) => {
    previousPreviewRef.value.parentElement.scroll({
      top: e.target.scrollTop,
      behavior: "instant",
    });
  };

  scrollToElement(diffInPrevView, { behavior: "instant" });
};

const loadDocumentHistory = async () => {
  const diff = await HtmlDiff.execute(
    previousEditorRef.value.innerHTML,
    currentEditorRef.value.innerHTML,
  );

  currentPreviewRef.value.innerHTML = diff;
  previousPreviewRef.value.innerHTML = previousEditorRef.value.innerHTML;

  scrollToChanges("instant");
};

// Interface navigation functions
const handleCloseHistoryPopup = () => emit("close");

const updateDocumentHistory = () => {
  const { previousState, newState } = getEditorContent();
  updateEditorContent(previousEditorView, previousState);
  updateEditorContent(currentEditorView, newState);
};

const handleChangeVersion = (direction) => {
  const isStart = historyIndex.value === 0 && direction === -1;
  const isEnd = historyIndex.value === history.value.length - 1 && direction === 1;
  if (isStart || isEnd) {
    return;
  }

  historyIndex.value += direction;

  previousContent.value = history.value[historyIndex.value === 0 ? 0 : historyIndex.value - 1];
  currentContent.value = history.value[history.value.length - 1];

  updateDocumentHistory();
  loadDocumentHistory();
};

const handleRestoreVersion = () => {
  if (!props.withRestore) {
    return;
  }
  store.commit("editor/setRestoreVersion", previousContent.value.snapshot);
  handleCloseHistoryPopup();
};

onMounted(async () => {
  await fetchDocumentHistory();

  const { previousState, newState } = getEditorContent();

  renderEditorContent(previousEditorRef.value, previousState);
  renderEditorContent(currentEditorRef.value, newState);

  loadDocumentHistory();
});
</script>

<template>
  <v-dialog v-model="props.isHistoryOpen">
    <div class="editor-history-modal">
      <div class="editor-history-modal--content">
        <div class="close-image-container">
          <img @click="handleCloseHistoryPopup" src="/images/icons/close.svg" alt="close" />
        </div>
        <div class="header-container">
          <h3>{{ $t("documents.change_history") }}</h3>
          <span> {{ formatDate(new Date()) }} </span>
        </div>
        <div class="content">
          <div class="previous-version">
            <div class="previous-version--header-container">
              <span class="previous-version--header">
                {{ $t("documents.previous_version") }}
              </span>
              <button class="restore-button" v-if="props.withRestore" @click="handleRestoreVersion">
                {{ $t("common.restore") }}
              </button>
            </div>
            <div class="previous-version-editor-container">
              <div ref="previousPreviewRef" id="previousPreview" class="preview"></div>
              <div ref="previousEditorRef" id="previousEditor" class="editor"></div>
              <div ref="previousContentRef" id="previousContent" class="editor-content" />
            </div>
          </div>
          <div class="current-version">
            <div class="current-version--header-container">
              <div class="current-version--header">
                {{ $t("documents.current_version") }}
                <span v-if="isMobile"> - {{ historyIndex + 1 }} </span>
              </div>

              <button
                class="restore-button"
                v-if="isMobile && props.withRestore && historyIndex + 1 < history.length"
                @click="handleRestoreVersion"
              >
                {{ $t("common.restore") }}
              </button>
            </div>
            <div class="current-version-editor-container">
              <div ref="currentPreviewRef" id="currentPreview" class="preview"></div>
              <div ref="currentEditorRef" id="currentEditor" class="editor" />
              <div ref="currentContentRef" id="currentContent" class="editor-content" />
            </div>
          </div>
        </div>
        <div class="content-footer">
          <button @click="() => handleChangeVersion(-1)">
            <img class="previous-arrow" alt="previous-arrow" src="/images/icons/arrow.svg" />
            {{ $t("documents.previous_version") }}
            {{ historyIndex }}
          </button>
          <button @click="() => handleChangeVersion(1)">
            {{ $t("documents.next_version") }}
            <img class="next-arrow" alt="next-arrow" src="/images/icons/arrow.svg" />
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<style scoped lang="scss">
.preview {
  height: 100%;
  width: 100%;
}
.editor {
  display: none;
}
.editor-content {
  display: none;
}
.editor-history-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 4;

  .editor-history-modal--content {
    @media (min-width: 760px) {
      min-height: 50%;
      max-height: 70vh;
    }

    background-color: white;
    border: 1px solid var(--grey);
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 80%;
    overflow-y: auto;

    .close-image-container {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      img {
        width: 25px;
        cursor: pointer;
      }
    }

    .header-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      color: var(--eerie-black);

      h3 {
        font-size: 1.5rem;
      }

      span {
        font-size: 0.875rem;
      }
    }

    .content {
      width: 100%;
      margin-top: 40px;
      min-height: 400px;
      display: flex;
      justify-content: space-between;

      .previous-version {
        width: 49%;
        max-height: 660px;
        border: 1px solid var(--grey);

        .previous-version--header-container {
          display: flex;
          width: 100%;
          justify-content: space-between;
          margin-bottom: 10px;
          background-color: var(--light-grey);

          .previous-version--header {
            text-align: left;
            color: var(--eerie-black);
            font-size: 0.875rem;
            font-weight: 600;
            padding: 15px 20px;
          }
        }

        .previous-version-editor-container {
          overflow-y: auto;
          max-height: 85%;
          padding-left: 15px;
          padding-right: 15px;
        }
      }

      .restore-button {
        background-color: var(--cambridge-blue);
        padding: 10px 40px;
        font-size: 1.125rem;
        color: white;
        font-weight: bold;
      }

      .current-version {
        width: 49%;
        max-height: 660px;
        border: 1px solid var(--grey);

        .current-version--header-container {
          display: flex;
          width: 100%;
          justify-content: space-between;
          margin-bottom: 10px;
          background-color: var(--light-grey);

          .current-version--header {
            text-align: left;
            color: var(--eerie-black);
            font-size: 0.875rem;
            font-weight: 600;
            padding: 15px 20px;
          }
        }
      }
      .current-version-editor-container {
        overflow-y: auto;
        max-height: 85%;
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .content-footer {
      margin-top: 20px;
      width: 100%;
      display: flex;
      justify-content: space-between;

      button {
        padding: 10px 30px;
        border-radius: 5px;
        background-color: var(--light-grey);
        color: var(--dark-grey);
        font-size: 0.688rem !important;
      }
    }
  }
  .previous-arrow {
    transform: rotate(90deg);
    padding-bottom: 2px;
  }

  .next-arrow {
    transform: rotate(-90deg);
    padding-bottom: 2px;
  }
}

@media (max-width: 760px) {
  .editor-history-modal--content {
    width: 100% !important;
    height: 100%;
  }

  .content {
    display: block !important;
    overflow: auto !important;
  }

  .previous-version {
    display: none;
  }

  .current-version {
    width: 100% !important;
  }

  :deep(.v-overlay__content) {
    min-width: 100% !important;
    max-width: 100% !important;
    margin-top: 95px !important;
    height: 100% !important;
  }
}
</style>
