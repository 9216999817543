import { schema as basicSchema } from "prosemirror-schema-basic";

import createStrong from "./marks/createStrong";
import createUnderline from "./marks/createUnderline";
import createFontSize from "./marks/createFontSize";
import createFontFamily from "./marks/createFontFamily";
import createTextColor from "./marks/createTextColor";
import createBackgroundColor from "./marks/createBackgroundColor";
import createComment from "./marks/createComment";
import createLineHeight from "./marks/createLineHeight";

const createMarks = () =>
  basicSchema.spec.marks.append({
    underline: createUnderline(),
    fontSize: createFontSize(),
    fontFamily: createFontFamily(),
    textColor: createTextColor(),
    comment: createComment(),
    backgroundColor: createBackgroundColor(),
  });

export default createMarks;
