<script setup>
import axios from "axios";
import { useToast } from "vue-toastification";
import { useI18n } from "vue-i18n";

const props = defineProps(["isDialogOpen", "document", "document"]);
const emit = defineEmits(["closeDialog", "updateDocumentName"]);
const toast = useToast();
const { t } = useI18n();
const newDocumentName = ref("");
const documenNameError = ref("");

const updateName = async () => {
  try {
    await axios.put(`api/documents/${props.document.id}/name`, {
      name: newDocumentName.value,
    });

    toast.success(t("notifications.document_name_updated"));
    emit("updateDocumentName", newDocumentName.value);
    emit("closeDialog");
  } catch (error) {
    documenNameError.value = error.response.data.message;
  }
};

watch(
  () => props.document,
  () => {
    newDocumentName.value = props.document.name;
  },
);
</script>

<template>
  <v-dialog v-model="props.isDialogOpen" max-width="600">
    <v-card>
      <div class="close-container">
        <img @click="emit('closeDialog')" src="/images/icons/close.svg" alt="close dialog" />
      </div>
      <v-card-title class="dialog-title">
        {{ $t("creator.change_document_name") }}
      </v-card-title>
      <v-card-text class="dialog-content">
        <v-text-field
          class="mt-4"
          v-model="newDocumentName"
          :label="$t('creator.change_document_name_description')"
          variant="outlined"
          required
        />

        <template v-if="documenNameError">
          <v-alert class="mt-4" type="error" border="left">
            {{ documenNameError }}
          </v-alert>
        </template>
      </v-card-text>

      <v-divider class="mb-5"></v-divider>

      <v-card-actions>
        <v-btn :text="$t('common.save')" variant="flat" @click="updateName"></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
.dialog-title {
  font-size: 1.5rem;
  font-weight: bold;
  padding: 23px 23px 0px;
}

.dialog-content {
  color: #858788;
}

.close-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-right: 20px;

  img {
    cursor: pointer;
  }
}
</style>
