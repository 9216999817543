import convertNode from "../convertNode";

export default function parseTable(xmlNode) {
  return {
    type: "table",
    attrs: {
      class: xmlNode.getAttribute("class"),
      id: crypto.randomUUID(),
    },
    content: Array.from(xmlNode.children).map(convertNode),
  };
}
