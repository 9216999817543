import { useChatCommands } from "@/utils/chatCommands/useChatCommands";

let socketRetryCount = 0;

export function useChatWebSocket(token, messages, store, t, saveMessageOnServer, showDisclaimer) {
  const socket = ref(null);
  const websocketUrl = import.meta.env.VITE_APP_CHAT_WEBSOCKET_URL;

  const handleOnOpen = () => {
    socketRetryCount = 0;
    console.log("Websocket connection established");
  };

  const handleOnMessage = (event) => {
    console.log("received event:", event);
    const { message_id, value, message_type, end_message, show_disclaimer } = JSON.parse(
      event.data,
    );

    const lastMessage = messages.value[messages.value.length - 1];
    const isBotMessage = lastMessage?.source === "bot";

    switch (message_type) {
      case "text":
        if (typeof value !== "string") {
          return;
        }
        if (lastMessage?.id === message_id && isBotMessage) {
          lastMessage.message += value;
        } else {
          messages.value.push({
            id: message_id,
            source: "bot",
            message: value,
            type: message_type,
          });
        }
        break;
      case "command":
        if (value?.commands) {
          Object.keys(value.commands).forEach((command) => {
            useChatCommands({
              command: command,
              ...value.commands[command],
            });
          });
        }

        break;
      default:
        return;
    }

    if (end_message) {
      const lastMessage = messages.value[messages.value.length - 1];
      console.log("is last message");
      saveMessageOnServer(lastMessage.message, "bot");
      console.log("lastMessage", lastMessage);
      console.log("messages.value", messages.value[messages.value.length - 1]);

      if (show_disclaimer && isBotMessage) {
        showDisclaimer();
      }
    }

    store.commit("chat/updateIsResponseLoading", false);
  };

  const handleOnClose = () => {
    console.log("Websocket connection closed");
    store.commit("chat/updateIsResponseLoading", false);
  };

  const handleOnError = (error) => {
    console.error("Websocket error: ", error);

    socketRetryCount++;
    const retryDelay = Math.min(3000 * socketRetryCount, 30000); // increase delay each time, but max out at 30s
    setTimeout(() => {
      setupWebsocket();
    }, retryDelay);
    store.commit("chat/updateIsResponseLoading", false);
  };

  const setupWebsocket = () => {
    // Close existing socket
    if (socket.value && socket.value.readyState !== WebSocket.CLOSED) {
      socket.value.close();
    }
    socket.value = new WebSocket(websocketUrl + "?token=" + token.value);
    socket.value.onopen = handleOnOpen;
    socket.value.onmessage = handleOnMessage;
    socket.value.onclose = handleOnClose;
    socket.value.onerror = handleOnError;
  };

  return {
    socket,
    setupWebsocket,
  };
}
