import convertNode from "../convertNode";

export default function parseDefinitionTerm(xmlNode) {
  return {
    type: "definition_term",
    content: Array.from(xmlNode.children)
      .map(convertNode)
      .filter((n) => n),
  };
}
