export function escapeXML(str) {
  return str
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&apos;");
}

export function escapeObjectValues(obj) {
  if (typeof obj === "string") {
    return escapeXML(obj);
  } else if (Array.isArray(obj)) {
    return obj.map(escapeObjectValues);
  } else if (typeof obj === "object" && obj !== null) {
    const escapedObj = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        escapedObj[key] = escapeObjectValues(obj[key]);
      }
    }
    return escapedObj;
  } else {
    return obj;
  }
}
