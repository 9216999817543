<script setup>
import { onMounted, ref } from "vue";
import axios from "axios";
import AccountForm from "@/components/users/profile/AccountForm.vue";
import AddressForm from "@/components/users/profile/AddressForm.vue";
import SubscriptionForm from "@/components/users/profile/subscription_form/SubscriptionForm.vue";
import Page from "@/components/Page.vue";
import Dropdown from "@/components/mobile/Dropdown.vue";
import { useIsMobile } from "@/utils/checkMobile";
import MobilePaymentHistoryTable from "@/components/users/profile/subscription_form/modal/payment_history/MobilePaymentHistoryTable.vue";
import setUserData from "@/utils/setUserData";
import { userLogoutHandler } from "@/utils/userLogoutHandler";
import BackButton from "@/components/BackButton.vue";
import SubscriptionFormVaiot from "@/components/users/profile/subscription_form/SubscriptionFormVaiot.vue";
import DeleteAccountDialog from "@/components/dialogs/DeleteAccountDialog.vue";
import { useToast } from "vue-toastification";
import ChangePasswordDialog from "@/components/dialogs/ChangePasswordDialog.vue";

const isMobile = useIsMobile();
const userData = ref({});
const isLoaded = ref(false);
const isDeleteAccountDialogOpen = ref(false);
const isChangePasswordDialogOpen = ref(false);

const shouldOpenSubscriptionDialog = computed(() => {
  return window.location.hash === "#subscription";
});

const showDeleteAccountDialog = () => {
  isDeleteAccountDialogOpen.value = true;
};

const showPasswordDialog = () => {
  isChangePasswordDialogOpen.value = true;
};

const handleLogout = async () => {
  const response = await axios.post("api/logout");
  if (response.status === 204) {
    store.commit("auth/logout");
    useToast().success($t("notifications.logout_success"));
    setTimeout(() => {
      router.push("/login");
    }, 200);
  } else {
    useToast().error($t("notifications.default_error"));
  }
};

const getUserData = async () => {
  try {
    const response = await axios.get("/api/user");
    userData.value = response.data.data;
  } catch (error) {
    console.error(error);
  } finally {
    isLoaded.value = true;
  }
};

onMounted(async () => {
  await setUserData();
  await getUserData();
});
</script>

<template>
  <Page :title="$t('profile.user_profile')" :with-spacing="true">
    <template #back-button>
      <BackButton
        :mobile="isMobile"
        :to="'/new-document'"
        margin-top="0"
        margin-bottom="0"
        class="mr-4"
      />
    </template>

    <div class="profile-container" v-if="isLoaded">
      <template v-if="isMobile">
        <Dropdown :title="$t('profile.contact_details')">
          <AccountForm :user-data="userData" />
        </Dropdown>
        <Dropdown :title="$t('profile.invoice_details')">
          <AddressForm :user-data="userData" />
        </Dropdown>
        <Dropdown :title="$t('billing.subscription')" :open="shouldOpenSubscriptionDialog">
          <SubscriptionFormVaiot
            v-if="userData.subscription_type === 'vaiot'"
            :subscription-data="userData.subscription_vaiot"
            @get-user-data="getUserData"
          />
          <SubscriptionForm
            v-else
            :subscription-data="userData.subscription"
            @get-user-data="getUserData"
          />
        </Dropdown>
        <Dropdown :title="$t('billing.payment_history')">
          <MobilePaymentHistoryTable />
        </Dropdown>

        <v-btn
          @click="showPasswordDialog"
          :text="$t('account.change_password')"
          class="delete-account-button mb-3"
          variant="flat"
          size="small"
        />

        <v-btn
          @click="showDeleteAccountDialog"
          :text="$t('account.delete_account')"
          class="delete-account-button mb-3"
          variant="flat"
          size="small"
        />

        <div class="logout-button-container">
          <VBtn @click="userLogoutHandler()" type="button" class="logout-button" variant="flat">
            {{ $t("auth.logout") }}
          </VBtn>
        </div>
      </template>
      <template v-else>
        <AccountForm :user-data="userData" />
        <AddressForm :user-data="userData" />
        <SubscriptionFormVaiot
          v-if="userData.subscription_type === 'vaiot'"
          :subscription-data="userData.subscription_vaiot"
          @get-user-data="getUserData"
        />
        <SubscriptionForm
          v-else
          :subscription-data="userData.subscription"
          @get-user-data="getUserData"
        />
      </template>
    </div>
    <DeleteAccountDialog
      :is-dialog-open="isDeleteAccountDialogOpen"
      @close-dialog="isDeleteAccountDialogOpen = false"
      @handle-logout="handleLogout"
    />
    <ChangePasswordDialog
      v-if="isChangePasswordDialogOpen"
      :isDialogOpen="isChangePasswordDialogOpen"
      @closeDialog="isChangePasswordDialogOpen = false"
    />
  </Page>
</template>

<style scoped lang="scss">
.logout-button-container {
  padding-bottom: 50px;
}

.form-button {
  font-size: 1.125rem;
  color: white !important;
  background-color: var(--cambridge-blue);
  text-transform: none;
  border-radius: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 195px;
  height: 45px;
}

.logout-button,
.delete-account-button {
  font-size: 1.125rem;
  color: white !important;
  background-color: var(--cambridge-blue);
  text-transform: none;
  border-radius: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  height: 35px;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
}

.profile-container {
  display: flex;
  justify-content: space-between;
  gap: 50px;

  @media (max-width: 786px) {
    display: block;

    .box {
      width: 100% !important;
      margin-bottom: 50px;
      min-width: auto !important;
    }
  }

  .box {
    min-width: 500px;
  }
}
</style>
