export default {
  inline: true,
  group: "inline",
  attrs: {
    name: {},
    description: {},
    value: {},
    id: {},
    answer: {},
  },
  toDOM: (node) => [
    "variable",
    {
      "data-name": node.attrs.name,
      "data-description": node.attrs.description,
      "data-value": node.attrs.value,
      "data-id": node.attrs.id,
      "data-answer": node.attrs.value,
      class: "variable-node",
    },
    `${node.attrs.answer || node.attrs.value}`,
  ],
  parseDOM: [
    {
      tag: "variable[data-name]",
      getAttrs: (dom) => ({
        name: dom.getAttribute("data-name"),
        description: dom.getAttribute("data-description"),
        value: dom.getAttribute("data-value"),
        id: dom.getAttribute("data-id"),
        answer: dom.getAttribute("data-answer"),
      }),
    },
  ],
};
