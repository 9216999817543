<script setup lang="ts">
import WizardQuestion from "./WizardQuestion.vue";
import { Step } from "@/types/editor/Wizard";

const { step } = defineProps<{
  step: Step;
}>();

const subSteps = ref(step.subSteps);
</script>

<template>
  <div class="wizard-step-container">
    <div class="wizard-step-header">
      <span> {{ step.description }} </span>
    </div>
    <div class="wizard-step-content">
      <div v-for="(subStep, index) in subSteps" :key="subStep.id">
        <WizardQuestion
          v-for="(question, index) in subStep.questions"
          :key="question.id"
          :question="question"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.wizard-step-container {
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  .wizard-step-header {
    text-align: left;
    font-size: 0.875rem;
    font-weight: 600;
    display: flex;
  }
}
</style>
