import createParagraph from "./nodes/createParagraph";
import createListItem from "./nodes/createListItem";
import createOrderedList from "./nodes/createOrderedList";

const createNodes = (nodes) => ({
  createParagraph: createParagraph(nodes),
  createListItem: createListItem(),
  createOrderedList: createOrderedList(nodes),
});

export default createNodes;
