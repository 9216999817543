<script setup>
import AlgoliaSearch from "@/components/documents/search_engine/AlgoliaSearch.vue";

const emit = defineEmits(["handle-template"]);

const handleTemplate = (templateId) => {
  emit("handle-template", templateId);
};
</script>

<template>
  <div class="templates-container">
    <p>{{ $t("documents.new_document_instruction_step_1") }}</p>
    <p>{{ $t("documents.new_document_instruction_step_2") }}</p>
    <p>{{ $t("documents.new_document_instruction_step_3") }}</p>
    <p>{{ $t("documents.new_document_instruction_step_4") }}</p>
    <div class="search-bar-container">
      <AlgoliaSearch @handle-template="handleTemplate" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.templates-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  p {
    font-size: 11px;
    line-height: 16px;
  }

  .search-bar-container {
    margin-top: 30px;
  }

  .templates {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 10px;

    .template {
      width: 32%;
    }
  }
}
</style>
