const chatModule = {
  namespaced: true,
  state() {
    return {
      isResponseLoading: false,
      sessionId: null,
      buttons: [],
    };
  },
  mutations: {
    updateIsResponseLoading(state, isResponseLoading) {
      state.isResponseLoading = isResponseLoading;
    },
    updateSessionId(state, sessionId) {
      state.sessionId = sessionId;
    },
    resetSessionId(state) {
      state.sessionId = null;
    },
    resetButtons(state) {
      state.buttons = [];
    },
    addButtons(state, buttons) {
      if (Array.isArray(buttons)) {
        state.buttons = buttons;
      } else {
        state.buttons = [buttons];
      }
    },
  },
};

export default chatModule;
