<script setup>
import { useI18n } from "vue-i18n";
import sanitizeHtml from "sanitize-html";

const { notification } = defineProps(["notification"]);
const emit = defineEmits(["delete", "show"]);

const { t } = useI18n();

const showNotification = async (notification) => {
  emit("show", notification);
};

const deleteNotification = async (notification) => {
  emit("delete", notification.id);
};
</script>
<template>
  <div :class="{ notification: true, readed: !!notification.read_at }">
    <div class="notification-date">
      <div>{{ t("common.dateOfCreation") }}: {{ notification.created_at }}</div>
      <div>
        <v-btn size="x-small" variant="text" @click="deleteNotification(notification)">
          <img src="/src/assets/icons/x.svg" alt="Delete notification" />
        </v-btn>
      </div>
    </div>
    <div @click="showNotification(notification)" class="notification-title">
      {{ notification.title }}
    </div>
    <div v-html="sanitizeHtml(notification.message)" class="notification-message" />
  </div>
</template>
<style scoped lang="scss">
.notification {
  color: var(--eerie-black);
  font-weight: 500;

  .notification-date {
    font-size: 0.75rem;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
  }

  .notification-title {
    font-size: 1.125rem;
    margin-bottom: 5px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  .notification-message {
    font-size: 0.875rem;
  }

  &.readed {
    .notification-title {
      font-weight: 400;
    }
    .notification-message {
      color: #858788;
      font-weight: 400;
    }
  }
}
</style>
