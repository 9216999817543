const createFontFamily = () => ({
  parseDOM: [{ tag: "span" }],
  toDOM: (node) => {
    const family = node.attrs.fontFamily || "";
    const style = `font-family:${family}`;
    return ["span", { style }, 0];
  },
  attrs: {
    fontFamily: { default: null },
  },
});

export default createFontFamily;
