import parseMeta from "./parseMeta";
import parseContent from "./parseContent";

export default function (documentContent: string, metaContent) {
  const documentObject = JSON.parse(documentContent);

  const documentContentArray = documentObject.content[0].content;

  let xmlDocument = document.implementation.createDocument(null, "", null);

  let documentElement = xmlDocument.createElement("document");
  let meta = xmlDocument.createElement("meta");
  let content = xmlDocument.createElement("content");

  documentElement.appendChild(parseMeta(meta, metaContent));
  documentElement.appendChild(parseContent(content, documentContentArray));
  xmlDocument.appendChild(documentElement);

  return new XMLSerializer().serializeToString(xmlDocument.documentElement);
}
