<script setup>
const isExpanded = ref(false);

const expand = () => {
  isExpanded.value = !isExpanded.value;
};

const arrowRotation = computed(() => {
  return isExpanded.value ? "rotate(180deg)" : "rotate(0deg)";
});
</script>

<template>
  <div class="instructions-container">
    <h4 class="instruction-header" @click="expand">
      <span>
        {{ $t("documents.new_document_instruction") }}
      </span>
      <img
        :style="{ transform: arrowRotation }"
        src="/images/icons/arrow.svg"
        alt="expand-instruction"
        class="expand-arrow"
      />
    </h4>
    <div class="instructions" :class="{ showing: isExpanded, hidden: !isExpanded }">
      <ol>
        <li>Choose a template that fits your needs.</li>
        <li>
          Unsure which design to choose? Describe your situation, and the assistant will suggest a
          suitable document or use our search function to find the document by its name.
        </li>
        <li>Fill in the document with your data.</li>
        <li>Sign the document electronically if necessary.</li>
      </ol>
    </div>
  </div>
</template>

<style scoped lang="scss">
.instructions-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  /* Negate the padding of the parent */
  margin: 0px -30px 30px;
  padding: 10px 20px;
  background-color: var(--light-grey);

  .instructions {
    display: flex;
    flex-direction: column;
    height: auto;
    transition: all 0.5s;
    overflow: hidden;
    color: var(--eerie-black);
    font-size: 0.875rem;
    margin-bottom: 10px;
    &.showing {
      max-height: 1000px;
      margin-top: 20px;
    }
    &.hidden {
      max-height: 0px;
    }

    ol li {
      list-style: decimal;
      list-style-position: inside;
    }
  }

  .instruction-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-weight: 600;
    font-size: 0.875rem;
    color: var(--eerie-black);
  }

  .expand-arrow {
    height: 5px;
    width: 10px;
    transition: 0.2s ease-in-out;
  }
}
</style>
