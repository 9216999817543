import convertNode from "../convertNode";

export default function parseItemContent(xmlNode) {
  return {
    type: "container",
    content: Array.from(xmlNode.children)
      .map(convertNode)
      .filter((n) => n),
  };
}
