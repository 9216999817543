const parseStyles = (style) => {
  const styles = style.split(";");
  const styleObject = {};
  styles.forEach((style) => {
    const [key, value] = style.split(":");

    switch (key) {
      case "text-align":
        styleObject.align = value;
        break;
      case "line-height":
        styleObject.lineHeight = value;
        break;
      case "font-size":
        styleObject.fontSize = value.split("px")[0];
        break;
      case "font-family":
        styleObject.fontFamily = value;
        break;
      case "color":
        styleObject.textColor = value;
        break;
      case "background-color":
        styleObject.backgroundColor = value;
        break;
    }
  });
  return styleObject;
};

export default parseStyles;
