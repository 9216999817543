<script setup>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
const { variant } = defineProps(["variant"]);
const emit = defineEmits(["click"]);

const { t } = useI18n();
const router = useRouter();

const isSubscriptionOpen = ref(false);
const isSingleDocumentOpen = ref(false);

const toggleSubscription = () => {
  isSubscriptionOpen.value = !isSubscriptionOpen.value;
};

const toggleSingleDocument = () => {
  isSingleDocumentOpen.value = !isSingleDocumentOpen.value;
};

const redirectToTemplates = () => {
  router.push("/new-document");
};
</script>

<template>
  <v-row>
    <v-col cols="12" outlined>
      <v-card class="pa-4 card-style">
        <v-row
          align="center"
          justify="space-between"
          @click="toggleSubscription"
          class="cursor-pointer"
        >
          <v-col cols="8">
            <h3 class="card-title">
              {{ t("billing.subscription") }}
            </h3>
          </v-col>
          <v-col cols="1">
            <v-icon>
              <img
                src="/images/icons/arrow.svg"
                class="arrow-image"
                :class="{ 'rotate-arrow': isSubscriptionOpen }"
                alt="image-arrow"
              />
            </v-icon>
          </v-col>
          <v-col cols="3" class="ml-0 text-right">
            <v-btn variant="flat" class="button-select" @click.stop="emit('click')">{{
              t("common.select")
            }}</v-btn>
          </v-col>
        </v-row>

        <v-expand-transition>
          <v-card v-show="isSubscriptionOpen" class="mx-auto border-0">
            <v-divider thickness="2"></v-divider>

            <v-list dense class="pt-4">
              <v-list-item class="align-start mb-4 list-item">
                <v-icon size="28" left color="blue" class="icon-style">mdi-information</v-icon>
                <div class="info-text">
                  {{ t("billing.join_premium_subscription") }}
                </div>
              </v-list-item>

              <v-list-item class="align-start mb-4 list-item">
                <v-icon size="28" left color="grey-darken-1" class="icon-style"
                  >mdi-check-circle</v-icon
                >
                <div class="text-content">
                  <div class="list-title">{{ t("billing.premium_subscription_details") }}</div>
                  <p class="list-subtitle">
                    {{ t("billing.premium_subscription_details_info") }}
                  </p>
                </div>
              </v-list-item>

              <v-list-item class="d-flex align-start mb-4 list-item">
                <v-icon size="28" left color="grey-darken-1" class="icon-style"
                  >mdi-check-circle</v-icon
                >
                <div class="text-content">
                  <div class="list-title">{{ t("billing.premium_subscription_ai_assistant") }}</div>
                </div>
              </v-list-item>

              <v-list-item class="d-flex align-start list-item">
                <v-icon size="28" left color="grey-darken-1" class="icon-style"
                  >mdi-check-circle</v-icon
                >
                <div class="text-content">
                  <div class="list-title">{{ t("billing.premium_subscription_dashboard") }}</div>
                </div>
              </v-list-item>
            </v-list>
          </v-card>
        </v-expand-transition>
      </v-card>
    </v-col>

    <v-col cols="12" outlined>
      <v-card class="pa-4 card-style">
        <v-row
          align="center"
          justify="space-between"
          @click="toggleSingleDocument"
          class="cursor-pointer"
        >
          <v-col cols="8">
            <h3 class="card-title">
              {{ t("billing.single_document") }}
            </h3>
          </v-col>
          <v-col cols="1">
            <v-icon>
              <img
                src="/images/icons/arrow.svg"
                class="arrow-image"
                :class="{ 'rotate-arrow': isSingleDocumentOpen }"
                alt="image-arrow"
              />
            </v-icon>
          </v-col>
          <v-col cols="3" class="text-right">
            <v-btn variant="flat" class="button-select" @click.stop="redirectToTemplates">{{
              t("common.findDocument")
            }}</v-btn>
          </v-col>
        </v-row>

        <v-expand-transition>
          <v-card v-show="isSingleDocumentOpen" class="mx-auto border-0">
            <v-divider thickness="2"></v-divider>

            <v-list dense class="pt-4">
              <v-list-item class="d-flex align-start mb-4 list-item">
                <v-icon size="28" left color="blue" class="icon-style">mdi-information</v-icon>
                <div class="info-text">
                  {{ t("billing.extended_option") }}
                </div>
              </v-list-item>

              <v-list-item class="d-flex align-start mb-4 list-item">
                <v-icon size="28" left color="grey-darken-1" class="icon-style"
                  >mdi-check-circle</v-icon
                >
                <div class="text-content">
                  <div class="list-title">{{ t("billing.selected_templates") }}</div>
                  <p class="list-subtitle">
                    {{ t("billing.selected_templates_details") }}
                  </p>
                </div>
              </v-list-item>

              <v-list-item class="d-flex align-start mb-4 list-item">
                <v-icon size="28" left color="grey-darken-1" class="icon-style"
                  >mdi-check-circle</v-icon
                >
                <div class="text-content">
                  <div class="list-title">{{ t("billing.ai_assistant") }}</div>
                  <p class="list-subtitle">
                    {{ t("billing.ai_assistant_details") }}
                  </p>
                </div>
              </v-list-item>

              <v-list-item class="d-flex align-start list-item">
                <v-icon size="28" left color="grey-darken-1" class="icon-style"
                  >mdi-check-circle</v-icon
                >
                <div class="text-content">
                  <div class="list-title">{{ t("billing.limited_dashboard") }}</div>
                  <p class="list-subtitle">
                    {{ t("billing.limited_dashboard_details") }}
                  </p>
                </div>
              </v-list-item>
            </v-list>
          </v-card>
        </v-expand-transition>
      </v-card>
    </v-col>
  </v-row>
</template>

<style scoped lang="scss">
.card-style {
  border: 2px solid #e0e0e0;
  padding: 0 !important;
  box-shadow: none;
}

.card-title {
  font-weight: bold;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.125rem;
  color: var(--blue-bonnet);
  margin-left: 15px;
  width: 100%;
}

.button-select {
  font-size: 0.8rem;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 0;
  width: 100px;
  color: black;
  background-color: var(--booger-buster);
}

.rotate-arrow {
  transform: rotate(180deg);
}

.v-divider {
  margin: 0 !important;
}

.v-list-item {
  display: flex;
  align-items: flex-start;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.v-icon {
  float: left;
}

.icon-style {
  margin-right: 15px;
  font-size: 1.5rem;
  flex-shrink: 0;
}

.text-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.info-text {
  display: flex;
  font-weight: normal;
  line-height: 1.4;
  font-size: 0.875rem;
  color: var(--eerie-black);
}

.list-title {
  font-size: 0.875rem;
  color: var(--eerie-black);
}

.list-subtitle {
  margin: 0;
  color: var(--eerie-black);
  font-size: 0.688rem;
}

.arrow-image {
  margin-left: auto !important;
}
</style>
