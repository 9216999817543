import { ref } from "vue";
import axios from "axios";
import useCookies from "@/utils/cookies";

export function useChatToken(chatId) {
  const cookies = useCookies();
  const token = ref(cookies.get("chat_token"));
  const tokenExpired = ref(1);

  const getChatToken = async () => {
    try {
      const apiUrl = import.meta.env.VITE_APP_API_BASE_URL;
      const response = await axios.post(`${apiUrl}/api/user-chat-tokens`, {
        chat_id: chatId.value,
      });

      token.value = response.data.token;
      tokenExpired.value = response.data.expires_at;
      cookies.set("chat_token", token.value, tokenExpired.value);

      await setNextTokenRenew(tokenExpired.value);
    } catch (error) {
      token.value = null;
      console.error("Error fetching chat token:", error);
      throw error;
    }
  };

  const setNextTokenRenew = async (expiresAtMs) => {
    const expiredTime = expiresAtMs * 1000 - Date.now();
    const oneMinuteInMs = 60000;

    setTimeout(async () => {
      await getChatToken();
    }, expiredTime - oneMinuteInMs);
  };

  return {
    token,
    tokenExpired,
    getChatToken,
  };
}
