import store from "@/store/store";
export default function parseVariableAttributes(variableId) {
  const variable = store.state.editor.availableVariables.find(
    (variable) => variable.id === variableId,
  );
  if (!variable) return;

  const { id, name, type, description, pattern, options, example, value } = variable;

  const newVariable = {
    name,
    description,
    value: value ?? name,
    id,
    type,
    answer: variable.answer,
    example: example || name,
  };

  if (options) {
    newVariable.options = options;
  }
  if (pattern) {
    newVariable.pattern = pattern;
  }

  return newVariable;
}
