const createTextColor = () => ({
  parseDOM: [{ tag: "span" }],
  toDOM: (node) => {
    const color = node.attrs.textColor || "#000000";
    const style = `color:${color}`;
    return ["span", { style }, 0];
  },
  attrs: {
    textColor: { default: null },
  },
});

export default createTextColor;
