<script setup>
import InputCustom from "./InputCustom.vue";
import clearObject from "../../utils/clearObject";
import { validateEmail, validateName, validatePassword } from "@/utils/validation";
import { Checkbox, useRecaptchaProvider } from "vue-recaptcha";
import { useI18n } from "vue-i18n";
import ContactSupportDialog from "@/components/dialogs/ContactSupportDialog.vue";

import sanitizeHtml from "sanitize-html";

useRecaptchaProvider();

const props = defineProps(["variant", "title", "errorMessage", "clearForm"]);
const emit = defineEmits(["submit"]);
const { t } = useI18n();

const formData = reactive({
  email: "",
  name: "",
  password: "",
  password_confirmation: "",
  google_recaptcha_response: "",
  remember_me: false,
});

const errors = reactive({
  name: "",
  email: "",
  password: "",
  password_confirmation: "",
});

const isLoading = reactive({
  email: false,
  google: false,
  apple: false,
  microsoft: false,
});

const recaptchaAttempt = ref(0);
const termsCheckbox = ref(false);

const termsAndPolicyText = computed(() => {
  const getText = (text, url) => `<a class="link" href="${url}" target="_blank">${text}</a>`;
  return t("auth.i_have_read", {
    terms: getText(t("static_pages.Terms Of Service"), "/terms-of-service"),
    privacyPolicy: getText(t("static_pages.Privacy Policy"), "/privacy-policy"),
  });
});

const isValid = () => {
  errors.name = props.variant === "register" ? validateName(formData.name) : "";
  errors.email = validateEmail(formData.email);
  errors.password =
    props.variant === "register" || props.variant === "Reset password"
      ? validatePassword(formData.password, formData.password_confirmation)
      : "";
  errors.google_recaptcha_response =
    props.variant !== "Reset Password" && formData.google_recaptcha_response === ""
      ? "Please check the reCAPTCHA checkbox"
      : "";
  return Object.values(errors).every((error) => error === "");
};

watch(
  () => formData,
  () => {
    clearObject(errors, "");
  },
  { deep: true },
);
watch(
  () => props.errorMessage,
  () => {
    clearObject(isLoading, false);
  },
);
watch(
  () => props.clearForm,
  () => {
    clearObject(isLoading, false);
    clearObject(formData, "");
  },
);

const handleSubmit = () => {
  if (isValid()) {
    emit("submit", formData);
    isLoading.email = false;
    recaptchaAttempt.value++;
  } else {
    isLoading.email = false;
  }
};
const handleGoogle = () => {
  emit("submit", "google");
  isLoading.google = !isLoading.google;
};
const handleApple = () => {
  emit("submit", "apple");
  isLoading.apple = !isLoading.apple;
};
const handleMicrosoft = () => {
  emit("submit", "microsoft");
  isLoading.microsoft = !isLoading.microsoft;
};

const isContactSupportDialogOpen = ref(false);

const showContactSupportDialog = () => {
  isContactSupportDialogOpen.value = true;
};
</script>

<template>
  <form class="form" @submit.prevent="handleSubmit">
    <h3 class="subtitle" :class="props.variant === 'register' ? 'mb-4' : ''">
      {{ props.title }}
    </h3>

    <h5 class="promo" v-if="props.variant === 'register'">
      {{ $t("auth.sign_up_promo") }}
    </h5>

    <InputCustom
      type="email"
      v-model="formData.email"
      :error="errors.email"
      :label="$t('common.email')"
      autocomplete="email"
    />

    <InputCustom
      v-if="props.variant === 'register'"
      type="name"
      v-model="formData.name"
      :error="errors.name"
      :label="$t('common.name')"
      autocomplete="name"
    />

    <InputCustom
      type="password"
      v-model="formData.password"
      :error="errors.password"
      :label="$t('auth.password')"
      autocomplete="password"
    />

    <InputCustom
      v-if="props.variant === 'register' || props.variant === 'Reset Password'"
      type="password"
      v-model="formData.password_confirmation"
      :error="errors.password_confirmation"
      :label="$t('auth.confirm_password')"
    />

    <v-checkbox
      class="checkbox"
      v-if="props.variant === 'register'"
      label="Checkbox"
      v-model="termsCheckbox"
    >
      <template v-slot:label>
        <div v-html="sanitizeHtml(termsAndPolicyText)"></div>
      </template>
    </v-checkbox>

    <router-link v-if="props.variant === 'login'" class="link mb-5" to="/forgot-password">
      <span>{{ $t("auth.forgot_password_link") }}</span>
    </router-link>

    <p v-if="props.errorMessage" class="errorMessage">
      {{ props.errorMessage }}
    </p>

    <Checkbox
      :key="recaptchaAttempt"
      v-if="props.variant !== 'Reset Password'"
      v-model="formData.google_recaptcha_response"
    />

    <template v-if="errors.google_recaptcha_response">
      <div class="recaptcha-error">{{ $t("auth.recaptcha") }}</div>
    </template>

    <div class="button-container">
      <v-btn
        type="submit"
        class="submit-button"
        block
        :loading="isLoading.email"
        :variant="props.variant === 'register' && !termsCheckbox ? 'tonal' : 'flat'"
        :disabled="props.variant === 'register' && !termsCheckbox"
      >
        {{ props.variant === "Reset Password" ? $t("common.save") : $t(`auth.${props.variant}`) }}
      </v-btn>
    </div>
    <p v-if="props.variant === 'register'" class="bottom-link">
      <router-link class="link" to="/login">
        <span class="link">
          {{ $t("auth.already_have_account") }}
          <span class="ml-1">{{ $t("auth.sign_in") }} </span>
        </span>
      </router-link>
    </p>
    <p v-if="props.variant === 'Reset password'" class="bottom-link">
      <router-link class="link" to="/login">
        <span> {{ $t("auth.remember_the_password") }} </span>
      </router-link>
    </p>
    <p v-if="props.variant === 'Reset password'" class="bottom-link">
      <v-btn class="link" @click="showContactSupportDialog">
        <span> {{ $t("auth.need_help") }} </span>
      </v-btn>
    </p>
    <p v-if="props.variant === 'login'" class="bottom-link-login">
      <router-link class="link" to="/register">
        <span class="link">{{ $t("auth.create_account") }}</span>
      </router-link>

      <v-checkbox v-model="formData.remember_me" :label="$t('auth.remember_me')" class="checkbox">
        <template v-slot:label>
          <span class="mr-1">{{ $t("auth.remember_me") }}</span>
          <v-tooltip location="bottom">
            <template v-slot:activator="{ props }">
              <v-icon v-bind="props" icon="mdi-help-circle-outline"></v-icon>
            </template>

            {{ $t("auth.remember_me_hint") }}
          </v-tooltip>
        </template>
      </v-checkbox>
    </p>

    <div v-if="props.variant !== 'Reset Password'" class="socials">
      <v-btn variant="plain" :loading="isLoading.apple" @click="handleApple" class="social-icon">
        <div class="apple-icon"></div>
      </v-btn>

      <v-btn :loading="isLoading.google" @click="handleGoogle" variant="plain" class="social-icon">
        <div class="google-icon"></div>
      </v-btn>

      <v-btn
        :loading="isLoading.microsoft"
        @click="handleMicrosoft"
        variant="plain"
        class="social-icon"
      >
        <div class="microsoft-icon"></div>
      </v-btn>
    </div>
  </form>
  <ContactSupportDialog
    :is-dialog-open="isContactSupportDialogOpen"
    @close-dialog="isContactSupportDialogOpen = false"
  />
</template>

<style scoped lang="scss">
$local-primary: rgb(138, 138, 138);

.form {
  width: 430px;
  height: 100%;
  border: 1px solid var(--grey);
  color: white;
  padding: 30px;
  margin: auto 5%;
  background-color: white;

  @media (max-width: 1000px) {
    margin: 0 auto;
    min-width: 340px;
  }
}

.google-icon {
  width: 30px;
  height: 30px;
  background-image: url("@/assets/icons/google.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.apple-icon {
  width: 28px;
  height: 34px;
  background-image: url("@/assets/icons/apple.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.microsoft-icon {
  width: 137px;
  height: 30px;
  background-image: url("@/assets/icons/microsoft.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.title {
  text-align: center;
  font-size: 2rem;
}

.promo {
  font-size: 0.875rem;
  color: #1d1d1d;
  text-align: center;
  font-weight: normal;
  padding-left: 70px;
  padding-right: 70px;
  margin-bottom: 20px;
}

.underline {
  text-decoration: underline;
}

.subtitle {
  text-align: center;
  margin-bottom: 50px;
  font-size: 1.5rem;
  color: var(--eerie-black);
}

.link {
  font-size: 0.75rem;
  color: var(--eerie-black);
  display: block;
  text-decoration: none;

  &:hover {
    color: rgb(var(--v-theme-secondary));
    text-decoration: underline;
  }
}

.link {
  font-size: 0.75rem;
  color: var(--eerie-black);
  display: flex;
  justify-content: end;
  text-decoration: underline;

  &:hover {
    color: rgb(var(--v-theme-secondary)) !important;
    text-decoration: underline;
  }
}

.bottom-link,
.bottom-link-login {
  text-align: left;
  color: var(--eerie-black);
  font-size: 0.75rem;

  a {
    color: var(--eerie-black);

    &:hover {
      color: rgb(var(--v-theme-primary));
      text-decoration: underline;
    }
  }
}

.button-container {
  margin-top: 30px;
}

.rights-info {
  margin-bottom: 5px;
  font-size: 0.875rem;
}

.submit-button {
  margin-bottom: 5px;
  background-color: var(--booger-buster);
  color: var(--eerie-black) !important;
  font-size: 1.125rem;
  font-weight: bold;
  height: 41px;
}

.divider {
  width: 100%;
  position: relative;
  margin-top: 30px;

  .line {
    height: 1px;
    width: 100%;
    background-color: $local-primary;
  }

  .text {
    border: 1px solid $local-primary;
    color: rgb(124, 124, 124);
    font-size: 0.75rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
    padding: 2px 5px;
  }

  .uppercase {
    text-transform: uppercase;
  }
}

.socials {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 20px;
}

.main-logo {
  width: 100%;
  margin-bottom: 30px;
}

.errorMessage {
  color: white;
  text-align: center;
  font-size: 0.875rem;
  background-color: red;
}

.checkbox {
  color: var(--eerie-black);
  font-size: 0.75rem;
  margin-left: -10px;
}

.checkbox :deep(a) {
  color: var(--eerie-black);
}

.checkbox :deep(a:hover) {
  color: rgb(var(--v-theme-secondary)) !important;
  text-decoration: underline;
}

.checkbox :deep(.v-label) {
  color: var(--eerie-black);
  font-size: 0.75rem;
  opacity: 1;
}

.recaptcha-error {
  color: rgb(var(--v-theme-error));
  font-size: 0.75rem;
}

@media (max-width: 768px) {
  .promo {
    padding: 0;
  }
}
</style>
