import axios from "axios";
import store from "@/store/store";
import { applyStyleToVariable } from "@/utils/editorStyling";
import pause from "@/utils/pause";

export default async function handleChangeVariable(variableId: string, newValue: string) {
  const documentId = store.state.editor.documentId;

  await axios.patch(`api/documents/${documentId}/meta/variables/answer`, {
    identifier: variableId,
    answer: newValue,
  });

  applyStyleToVariable(variableId);
  await pause(1000);

  store.commit("editor/updateVariable", {
    id: variableId,
    value: newValue,
    answer: newValue,
  });

  await pause(1000);
  applyStyleToVariable(null);
}
