<script setup>
import { useRouter } from "vue-router";

const router = useRouter();

const handleGoBack = () => {
  router.back();
};
</script>
<template>
  <div class="container-not-found">
    <div class="content">
      <h1>404</h1>
      <h2>You didn't break the internet,<br />but we can't find what you are looking for.</h2>
      <p>Just <span class="link" @click="handleGoBack">go back</span> or click on logo :)</p>
    </div>
  </div>
</template>
<style scoped lang="scss">
.container-not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 20px;
    position: relative;
    margin-left: 280px;
    padding-right: 20px;
    padding-left: 20px;
    @media (max-width: 945px) {
      margin-left: 0;
    }
  }

  h1 {
    font-size: 72px;
    color: #6b889a;
  }

  h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #6b889a;
  }

  p {
    font-size: 1.375rem;
    color: #b2b4b9;
  }

  .link {
    font-weight: 600;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
