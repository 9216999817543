<script setup>
import { useI18n } from "vue-i18n";
import { useIsMobile } from "@/utils/checkMobile";

const { subscription } = defineProps(["subscription"]);
const emit = defineEmits(["showHistory", "resumeSubscription", "cancelPlan"]);
const isMobile = useIsMobile();
const { t } = useI18n();

const customerPortalUrl = import.meta.env.VITE_STRIPE_BILLING_PORTAL;
</script>
<template>
  <div class="icons-container">
    <template v-if="subscription.status === 'canceled'">
      <VBtn
        v-if="subscription.to"
        @click="resumeSubscription"
        color="warning"
        variant="outlined"
        size="small"
      >
        {{ t("common.resume") }}
      </VBtn>

      <VBtn v-else :href="customerPortalUrl" color="warning" variant="outlined" size="small">
        {{ t("common.resume") }}
      </VBtn>
    </template>
    <template v-else>
      <!-- EDIT -->
      <a :href="customerPortalUrl" class="icon-link" target="_blank">
        <img src="/images/icons/edit.svg" alt="Edit subscription" class="icon" />
      </a>

      <!-- HISTORY (Disabled for mobile view)-->
      <img
        v-if="!isMobile"
        @click="emit('showHistory')"
        src="/images/icons/list.svg"
        alt="List payments"
        class="icon"
      />

      <!-- CANCEL SUBSCRIPTION -->
      <img
        @click="emit('cancelPlan')"
        src="/images/icons/cross.svg"
        alt="Cancel subscription"
        class="icon"
      />
    </template>
  </div>
</template>
<style scoped lang="scss">
.icons-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.icon,
.icon-link {
  transition: 0.2s ease;
  cursor: pointer;
}
.icon:hover {
  filter: brightness(0.6);
}
</style>
