import convertNode from "../convertNode";

export default function parseValue(xmlNode) {
  if (xmlNode.getAttribute("type") === "inline") {
    if (xmlNode.children.length) {
      return {
        type: "inlineContainer",
        content: Array.from(xmlNode.children)
          .map(convertNode)
          .filter((n) => n),
      };
    }

    return {
      type: "text",
      text: " " + xmlNode.textContent + " ",
    };
  }

  return {
    type: "container",
    content: Array.from(xmlNode.children)
      .map(convertNode)
      .filter((n) => n),
  };
}
