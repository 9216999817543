import { Schema } from "prosemirror-model";
import { addListNodes } from "prosemirror-schema-list";
import { schema } from "prosemirror-schema-basic";
import variableNode from "../nodes/variableNode";
import containerNode from "../nodes/containerNode";
import { tableNodes } from "prosemirror-tables";
import inlineContainerNode from "../nodes/inlineContainerNode";

import createNodes from "./factories/createNodes";
import createMarks from "./factories/createMarks";

const nodes = addListNodes(schema.spec.nodes, "paragraph block*", "block").append({
  variable: variableNode,
  container: containerNode,
  inlineContainer: inlineContainerNode,
});

const { createParagraph, createListItem, createOrderedList } = createNodes(nodes);

const newListSchema = new Schema({
  nodes: nodes
    .update("paragraph", createParagraph)
    .update("list_item", createListItem)
    .update("ordered_list", createOrderedList)
    .append(
      tableNodes({
        cellAttributes: {},
        tableGroup: "block",
        cellContent: "block+",
      }),
    ),
  marks: createMarks(),
});

export default newListSchema;
