<script setup lang="ts">
import Editor from "@/components/ProseMirror/Editor.vue";

const { document, isWizardCompleted, editorEditable } = defineProps<{
  document: Object;
  isWizardCompleted: boolean;
  editorEditable: boolean;
}>();

const emit = defineEmits(["documentUpdated", "variableClicked"]);

const isLoading = computed(() => !isWizardCompleted);

const handleDocumentUpdated = (newDocument) => {
  emit("documentUpdated", newDocument);
};
</script>

<template>
  <div class="wysiwyg-container">
    <div class="blur" v-if="isLoading">
      <div class="message">
        <p>{{ $t("creator.wysiwyg_wizard_locked") }}</p>
        <span class="mdi mdi-chevron-right"></span>
      </div>
    </div>
    <Editor
      :key="document.id"
      :document="document"
      :editable="editorEditable"
      @documentUpdated="handleDocumentUpdated"
      @variable-clicked="emit('variableClicked')"
    />
  </div>
</template>

<style scoped lang="scss">
.wysiwyg-container {
  display: flex;
  flex-direction: column;
  height: calc(100% - 65px);
  width: 100%;
  position: relative;
}
.blur {
  backdrop-filter: blur(5px);
  width: calc(100% - 2px);
  height: calc(100% + 110px);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;

  .message {
    display: flex;
    align-items: center;
    background-color: #1d1d1d;
    width: 300px;
    margin: 50px auto 0;
    padding: 10px;
    font-size: 1.5rem;
    color: white;
    text-align: center;
  }
}
</style>
