export function validateName(name) {
  if (!name.trim()) {
    return "Field is required";
  }
  return "";
}

export function validateEmail(email) {
  const regex = /^[\w+-.]+@([\w-]+\.)+[\w-]{2,4}$/g;

  if (!email) {
    return "Field is required";
  }
  if (!regex.test(email)) {
    return "Invalid email address";
  }
  return "";
}

export function validatePassword(password, repeatedPassword, isRequired = true) {
  if (!isRequired && !password) {
    return "";
  }

  if (!password) {
    return "Field is required";
  }
  if (password.length < 8) {
    return "Password must have at least 8 characters";
  }
  if (password !== repeatedPassword) {
    return "Passwords must be the same";
  }
  if (!/\d/.test(password)) {
    return "Password must contain at least one digit";
  }
  if (!/[A-Z]/.test(password)) {
    return "Password must contain at least one uppercase letter";
  }
  if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
    return "Password must contain at least one special character";
  }
  return "";
}

export function validatePhone(phone, isRequired = false) {
  const regex = /^[\+]?[0-9]{0,3}\W?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

  if (!isRequired && !phone) {
    return "";
  }

  if (!phone) {
    return "Field is required";
  }
  if (!regex.test(phone)) {
    return "Invalid phone number";
  }
  return "";
}
