export default function parseContent(content, contentArray = []) {
  if (!contentArray || contentArray.length === 0) {
    return content;
  }

  contentArray.forEach((element) => {
    switch (element.type) {
      case "text":
        const textNode = document.createTextNode(element.text);

        if (hasMarks(element)) {
          const span = document.createElement("span");
          span.appendChild(textNode);
          const elementWithStyles = addStylesFromMarks(span, element.marks);
          content.appendChild(elementWithStyles);
        } else {
          content.appendChild(textNode);
        }
        break;
      case "paragraph":
        const paragraph = document.createElement("paragraph");
        paragraph.setAttribute("startNewPage", element.attrs.startNewPage);
        paragraph.setAttribute("id", element.attrs.id);
        content.appendChild(parseContent(paragraph, element.content));
        break;
      case "variable":
        const variable = document.createElement("variable");
        variable.setAttribute("id", element.attrs.id);
        variable.setAttribute("name", element.attrs.name);
        variable.setAttribute("value", element.attrs.value);
        content.appendChild(variable);
        break;
      case "container":
        parseContent(content, element.content);
        break;
      case "ordered_list":
        const listNode = document.createElement("list");
        listNode.setAttribute("id", element.attrs.id);
        listNode.setAttribute("class", element.attrs.class);
        content.appendChild(parseContent(listNode, element.content));
        break;
      case "list_item":
        const listItem = document.createElement("item");
        listItem.setAttribute("id", element.attrs.id);

        const isLegal = Array.from(content.classList).includes("legal");
        isLegal && listItem.setAttribute("class", "legal-paragraph");

        // element.attrs.class &&
        //   listItem.setAttribute("class", element.attrs.class);

        content.appendChild(parseContent(listItem, element.content));
        break;
    }
  });

  return content;
}

const hasMarks = (element) => {
  return element.marks && element.marks.length > 0;
};

const addStylesFromMarks = (element, marks) => {
  marks.forEach((mark) => {
    switch (mark.type) {
      case "strong":
        element.style.fontWeight = "bold";
        break;
      case "underline":
        element.style.textDecoration = "underline";
        break;
      case "em":
        element.style.fontStyle = "italic";
        break;
      case "fontSize":
        element.style.fontSize = `${mark.attrs.fontSize}px`;
        break;
      case "fontFamily":
        element.style.fontFamily = `${mark.attrs.fontFamily}`;
        break;
      case "textColor":
        element.style.color = `${mark.attrs.textColor}`;
        break;
      case "backgroundColor":
        element.style.backgroundColor = `${mark.attrs.backgroundColor}`;
        break;
    }
  });

  return element;
};
