export default function checkAlign(state, type) {
  const { from, to } = state.selection;
  let active = false;
  state.doc.nodesBetween(from, to, (node) => {
    if (node.attrs.align === type) {
      active = node.attrs.align === type;
    }
  });
  return active;
}
