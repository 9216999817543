<script setup>
import { useI18n } from "vue-i18n";

const { t } = useI18n();
</script>
<template>
  <tr>
    <th class="text-left">ID</th>
    <th class="text-left">{{ t("common.type") }}</th>
    <th class="text-left">{{ t("common.amount") }}</th>
    <th class="text-left">{{ t("billing.payment_date") }}</th>
  </tr>
</template>
<style scoped lang="scss">
th {
  border-right: 1px solid var(--grey);
}
</style>
