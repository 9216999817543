<script setup>
import DocumentCard from "@/components/documents/list/DocumentCard.vue";
import { documentStatuses } from "@/utils/statuses";
import axios from "axios";
import { ref } from "vue";

const { items } = defineProps(["items"]);

const documents = ref([]);
const getDocuments = async () => {
  try {
    const response = await axios.get("/api/documents", {
      params: {
        per_page: items,
        sort: "-created_at",
      },
    });

    if (!documents.value.length) {
      documents.value = response.data.data;
    }
  } catch (error) {
    console.error(error);
  }
};

onMounted(() => {
  getDocuments();
});
</script>

<template>
  <div class="latest-documents-container">
    <div class="latest-documents-header">
      <h3>{{ $t("home.latest_documents") }}</h3>
    </div>
    <div class="latest-documents">
      <div v-for="document in documents" v-bind:key="document.id">
        <DocumentCard :document="document" :documentStatuses="documentStatuses" :directories="[]" />
      </div>
      <div v-if="documents.length === 0">
        <p>{{ $t("errors.documents.not_found") }}</p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.latest-documents-container {
  display: flex;
  flex-direction: column;

  .latest-documents-header {
    width: 100%;
  }

  .latest-documents {
    display: flex;
    gap: 20px;
    padding: 10px 0;
  }
}
</style>
