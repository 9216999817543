import Login from "@/views/auth/Login.vue";
import Register from "@/views/auth/Register.vue";
import ForgotPassword from "@/views/auth/ForgotPassword.vue";
import ResetPassword from "@/views/auth/ResetPassword.vue";
import LoginByCallback from "@/views/auth/LoginByCallback.vue";
import VerifyEmail from "@/views/auth/VerifyEmail.vue";
import VerificationRequired from "@/views/auth/VerificationRequired.vue";

const routesAuth = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/login/:provider",
    name: "LoginByCallback",
    component: LoginByCallback,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/verify-email/:userId/:token",
    name: "VerifyEmail",
    component: VerifyEmail,
  },
  {
    path: "/verification-required",
    name: "VerificationRequired",
    component: VerificationRequired,
  },
];

export default routesAuth;
