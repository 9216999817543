import { applyStyleToParagraph, removeStyles } from "@/utils/editorStyling";

type commandType = "info" | "danger";

export function handleSelectParagraph(ids: string[], type: commandType) {
  ids.forEach((id) => {
    applyStyleToParagraph(id, type);
  });
}

export function handleRemoveParagraphsSelection() {
  removeStyles("paragraph");
}
