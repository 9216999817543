export default {
  group: "block",
  content: "(paragraph | container | table)+ (ordered_list block*)*",
  toDOM: (node) => [
    "div",
    {
      class: "container",
      "data-id": node.attrs.id || null,
    },
    0,
  ],
  attrs: {
    id: { default: null },
    class: { default: null },
  },
  parseDOM: [{ tag: "div.container" }],
};
