<script setup>
import { defineModel } from "vue";

const props = defineProps(["type", "error", "label"]);
const model = defineModel();

const showPassword = ref(false);

const togglePasswordPreview = () => {
  showPassword.value = !showPassword.value;
};
</script>

<template>
  <v-text-field
    v-if="props.type === 'name'"
    type="text"
    v-model="model"
    :label="props.label"
    variant="outlined"
    density="compact"
    :error-messages="props.error"
    class="text-input"
  ></v-text-field>

  <v-text-field
    v-else-if="props.type === 'email'"
    type="email"
    v-model="model"
    :label="props.label"
    variant="outlined"
    density="compact"
    :error-messages="props.error"
    class="text-input"
  ></v-text-field>

  <v-text-field
    v-else-if="props.type === 'password'"
    :type="showPassword ? 'text' : 'password'"
    v-model="model"
    :label="props.label"
    variant="outlined"
    density="compact"
    :error-messages="props.error"
    class="text-input"
  >
    <template v-slot:append-inner>
      <img
        @click="togglePasswordPreview"
        v-if="!showPassword"
        src="/images/icons/eye.svg"
        alt="Show password"
      />
      <img
        v-else
        @click="togglePasswordPreview"
        src="/images/icons/eye-closed.svg"
        alt="Hide password"
      />
    </template>
  </v-text-field>

  <v-checkbox
    v-else-if="props.type === 'checkbox'"
    v-model="model"
    :label="props.label"
    :error-messages="props.error"
    class="checkbox-input"
  >
    <template v-slot:label>
      <span class="checkbox-label">{{ props.label }}</span>
    </template>
  </v-checkbox>
</template>
<style scoped lang="scss">
.text-input {
  color: #bebebe;
  margin-bottom: 10px;
}
.checkbox-input {
  color: #bebebe;
}
.checkbox-label {
  color: var(--eerie-black) !important;
  font-size: 0.75rem;
}
:deep(.v-field__append-inner) {
  cursor: pointer;
}
</style>
