// Styles

import "vuetify/styles";
import "@mdi/font/css/materialdesignicons.css";
import { VTreeview } from "vuetify/labs/VTreeview";

// Composables
import { createVuetify } from "vuetify";

export default createVuetify({
  display: {
    mobileBreakpoint: "sm",
    thresholds: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  theme: {
    themes: {
      light: {
        colors: {
          background: "#f7f7f7",
          primary: "#d1ee76",
          secondary: "#9AB9B3",
          default: "#698680",
          dark: "#133072",
          text: "#083957",
          accent1: "#AFD3EB",
          accent2: "#0EA1EE",
          accent3: "#EE879C",
          accent4: "#EEEDEB",
          custom: "#F7F7F7",
        },
      },
    },
  },
  defaults: {
    VBtn: {
      rounded: 0,
    },
  },
  icons: {
    defaultSet: "mdi",
  },
  components: {
    VTreeview,
  },
});
