<template>
  <v-dialog v-model="props.isDialogOpen" max-width="500">
    <v-form @submit.prevent="sendEmailWithDocument" ref="sendEmailWithDocumentForm">
      <v-card title="Share Document via Email">
        <v-card-text>
          Please enter the email address to which you would like to send the document.

          <v-text-field
            v-model="recipientEmail"
            :rules="emailRules"
            class="mt-3"
            label="Email"
            outlined
            dense
            clearable
            required
            variant="underlined"
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-btn text="Cancel" variant="tonal" @click="emit('closeDialog')"></v-btn>

          <v-spacer></v-spacer>

          <v-btn type="submit" color="primary" variant="flat"> Send </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script setup>
import axios from "axios";
import { useToast } from "vue-toastification";

const props = defineProps(["isDialogOpen", "document"]);
const emit = defineEmits(["closeDialog"]);
const toast = useToast();
const sendEmailWithDocumentForm = ref();
const recipientEmail = ref(null);

const sendEmailWithDocument = async () => {
  if (await sendEmailWithDocumentForm.value.validate().valid) {
    return;
  }

  try {
    await axios.post(`/api/documents/${props.document.id}/send-email`, {
      recipient_email: recipientEmail.value,
    });

    toast.success("Email sent successfully");
  } catch (error) {
    toast.error("Error sending email. Try again later.");
  }

  emit("closeDialog");
  recipientEmail.value = null;
};

const emailRules = [
  (v) => !!v || "Email is required",
  (v) => /.+@.+\..+/.test(v) || "Email must be valid",
];
</script>
