<script setup>
import schema from "@/components/ProseMirror/schemas/defaultSchema";
import checkMarkActive from "@/components/ProseMirror/menu/utils/checkMarkActive";
import { useI18n } from "vue-i18n";

import updateMark from "@/components/ProseMirror/menu/utils/updateMark";

const { editorView } = defineProps(["editorView"]);
const { t } = useI18n();
const localState = reactive({
  textColor: "#000000",
  backgroundColor: "#ffffff",
});

const handleChangeMark = async (type) => {
  const attrs = { [type]: localState[type] };
  updateMark(schema.marks[type], attrs, editorView.state, editorView.dispatch);

  editorView.focus();
};

const updateLocalState = (state) => {
  const newTextColor = checkMarkActive(state, schema.marks.textColor);
  localState.textColor = newTextColor || "#000000";

  const newBackgroundColor = checkMarkActive(state, schema.marks.backgroundColor);

  localState.backgroundColor = newBackgroundColor || "#ffffff";
};

onMounted(() => {
  updateLocalState(editorView.state);
});
</script>
<template>
  <div class="tab-content__items">
    <div class="tab-content__marks">
      <div class="tab-content__item">
        <input
          type="color"
          v-model="localState.textColor"
          @change="() => handleChangeMark('textColor')"
        />
        {{ t("creator.toolbar.text").toUpperCase() }}
      </div>

      <div class="tab-content__item">
        <input
          type="color"
          v-model="localState.backgroundColor"
          @change="() => handleChangeMark('backgroundColor')"
        />
        {{ t("creator.toolbar.background").toUpperCase() }}
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.tab-content {
  &__items {
    height: 100%;
    padding: 0 15px;
    padding-bottom: 15px;

    .tab-content__marks {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 15px;
    }

    .tab-content__item {
      // border: 1px solid var(--grey);
      padding: 0px 15px;
      font-size: 0.875rem;
      font-weight: 600;
      flex: 1;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      input:hover {
        cursor: pointer;
      }

      .mark__icon {
        width: 30px;
        height: 30px;
        background-color: var(--dark-grey);
      }

      &.active {
        .mark__icon {
          background-color: var(--blue-bonnet);
        }
      }
    }
  }
}
</style>
