<script setup>
import { defineProps, onMounted, ref, defineEmits } from "vue";
import { useI18n } from "vue-i18n";
import axios from "axios";

import PaymentHistoryHeader from "./PaymentHistoryHeader.vue";
import PaymentHistoryItem from "./PaymentHistoryItem.vue";

const { t } = useI18n();

const { open } = defineProps(["open"]);
const emit = defineEmits(["close"]);

const invoices = ref([]);
const isLoaded = ref(false);

const isOpen = computed({
  get: () => open,
  set: (value) => {
    emit("close");
  },
});

const getPaymentHistory = async () => {
  const response = await axios.get("/api/user/subscription/history");
  invoices.value = response.data;
  isLoaded.value = true;
};

const handleDownloadInvoice = async (invoiceId) => {
  const response = await axios.get("api/user/invoices/" + invoiceId, {
    responseType: "blob",
  });
  const file = new File([response.data], "invoice.pdf", {
    type: "application/pdf",
  });
  const url = URL.createObjectURL(file);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", file.name);
  document.body.appendChild(link);
  link.click();
};

onMounted(async () => {
  await getPaymentHistory();
});
</script>
<template>
  <VDialog v-model="isOpen" max-width="1200">
    <VCard class="text-center">
      <div class="close-container">
        <img
          @click="() => emit('close')"
          src="/images/icons/close.svg"
          alt="close
        dialog"
        />
      </div>
      <span class="payment-dialog-title">
        {{ t("billing.payment_history") }}
      </span>
      <v-table class="history-table" v-if="isLoaded">
        <tbody>
          <PaymentHistoryHeader />

          <PaymentHistoryItem
            v-for="invoice in invoices.data"
            :key="invoice.id"
            :invoice="invoice"
            @download="() => handleDownloadInvoice(invoice.id)"
          />
        </tbody>
      </v-table>
    </VCard>
  </VDialog>
</template>
<style scoped lang="scss">
.v-table {
  border: 1px solid var(--grey);
  border-right: none;
}

.history-table {
  margin: 20px 50px 150px;
}

td {
  border-right: 1px solid var(--grey);
}

.payment-date-data {
  min-width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payment-dialog-title {
  display: flex;
  width: 100%;
  justify-content: start;
  margin-left: 50px;
  font-weight: bold;
  color: var(--eerie-black);
  margin-top: 20px;
  margin-bottom: 40px;
  font-size: 1.5rem;
}

.download-icon {
  cursor: pointer;
  width: 12px;
}

.close-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-right: 20px;

  img {
    cursor: pointer;
  }
}
</style>
