<script setup>
import axios from "axios";
import { ref } from "vue";
import PopularTemplateCard from "@/views/app/mobile/PopularTemplateCard.vue";

const emit = defineEmits(["handle-template"]);
const { items } = defineProps(["items"]);
const templates = ref([]);

const isExpanded = ref(false);

const expand = () => {
  isExpanded.value = !isExpanded.value;
};

const arrowRotation = computed(() => {
  return isExpanded.value ? "rotate(180deg)" : "rotate(0deg)";
});

const getTemplatesList = async () => {
  const response = await axios.get("api/templates", {
    params: {
      page: 1,
      per_page: items,
      sort: "-documents_count",
    },
  });
  templates.value = response.data.data;
};

const handleTemplate = (templateId) => {
  emit("handle-template", templateId);
};

onMounted(() => {
  getTemplatesList();
});
</script>

<template>
  <div class="latest-templates-container">
    <div class="latest-templates-header" @click="expand">
      <h3>{{ $t("documents.popular_templates") }}</h3>
      <img
        :style="{ transform: arrowRotation }"
        src="/images/icons/arrow.svg"
        alt="expand-instruction"
        class="expand-arrow"
      />
    </div>
    <div class="latest-templates" :class="{ showing: isExpanded, hidden: !isExpanded }">
      <div v-for="template in templates" :key="template.id">
        <PopularTemplateCard @handle-template="handleTemplate" :template="template" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.latest-templates-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;

  .latest-templates-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    width: 100%;
    color: var(--eerie-black);
    font-size: 0.875rem;
    font-weight: 600;
  }

  .latest-templates {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 10px;

    height: auto;
    transition: all 0.5s;
    overflow: hidden;
    &.showing {
      max-height: 1000px;
      margin-top: 10px;
    }
    &.hidden {
      max-height: 0px;
      margin-top: 0;
    }
  }
}

.expand-arrow {
  height: 5px;
  width: 10px;
  transition: 0.2s ease-in-out;
}
</style>
