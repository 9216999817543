<script setup>
import { useStore } from "vuex";
import WizardStep from "./WizardStep.vue";
import axios from "axios";
import { useIsMobile } from "@/utils/checkMobile";

const emit = defineEmits(["completeWizard", "noActionTaken"]);
const { isAiAssistantOpen } = defineProps(["isAiAssistantOpen"]);

const store = useStore();

const buttonREF = {
  next: ref(null),
  save: ref(null),
};

const answers = computed(() => store.state.editor.wizard.answers);
const steps = computed(() => store.state.editor.wizard.steps);
const currentStep = ref(0);
const actionTaken = ref(false);
let actionTimer = null;
const isStepCompleted = computed(() => {
  const currentStepId = steps.value[currentStep.value]?.id;
  return answers.value
    .filter((answer) => answer.stepId === currentStepId)
    .every((answer) => !!answer.answer);
});
const progress = computed(() => (currentStep.value / steps.value.length) * 100);
const documentId = computed(() => store.state.editor.documentId);
const isMobile = useIsMobile();

const handleNextClick = () => {
  actionTaken.value = true;
  clearTimeout(actionTimer);
  axios.patch(
    `api/documents/${documentId.value}/meta/${answers.value[currentStep.value].questionId}/answer`,
    {
      answer: answers.value[currentStep.value].answer,
    },
  );

  currentStep.value = getNextStepIndex();
  if (currentStep.value === null) {
    emit("completeWizard");
  }
};

const getNextStepIndex = () => {
  for (let i = currentStep.value + 1; i < steps.value.length; i++) {
    if (shouldStepBeDisplayed(i)) {
      return i;
    }
  }
  return null;
};

const getPreviousStepIndex = () => {
  for (let i = currentStep.value - 1; i >= 0; i--) {
    if (shouldStepBeDisplayed(i)) {
      return i;
    }
  }
  return currentStep.value;
};

const handlePreviousClick = () => {
  actionTaken.value = true;
  clearTimeout(actionTimer);
  currentStep.value = getPreviousStepIndex();
  isStepCompleted.value = !!answers.value[currentStep.value];
};

const handleSave = () => {
  actionTaken.value = true;
  clearTimeout(actionTimer);
  axios.patch(
    `api/documents/${documentId.value}/meta/${answers.value[currentStep.value].questionId}/answer`,
    {
      answer: answers.value[currentStep.value].answer,
    },
  );
  emit("completeWizard");
};

const shouldStepBeDisplayed = (stepNumber) => {
  const step = steps.value[stepNumber];
  // For no conditions defined return true as the step should display always
  if (step.conditions.length === 0) {
    return true;
  }
  const conditions = step.conditions;

  // If there are conditions check all of them because all of them should pass
  let display = true;
  conditions.forEach((condition) => {
    const answer = answers.value.find((answer) => answer.questionId === condition.question_id);
    if (answer.answer !== condition.value) {
      display = false;
    }
  });

  return display;
};

watch(
  () => currentStep.value,
  () => {
    const stepId = steps.value[currentStep.value].id;
    store.commit("editor/setWizardCurrentStep", stepId);

    actionTaken.value = false;
    clearTimeout(actionTimer);
    actionTimer = setTimeout(() => {
      if (!actionTaken.value) {
        emit("noActionTaken", { id: stepId, type: "wizard" });
      }
    }, 60000); // 1 minute
  },
  { immediate: true },
);

watch(
  () => answers.value,
  async () => {
    const isLastStep = currentStep.value + 1 === steps.value.length;

    await nextTick();
    const button = isLastStep ? buttonREF.save : buttonREF.next;
    button.value.$el.focus();
  },
  { deep: true },
);
</script>

<template>
  <div class="wizard-container" :style="{ height: !isAiAssistantOpen ? '100%' : '50%' }">
    <!-- Mobile view -->
    <template v-if="isMobile">
      <div class="progress-bar">
        <span>
          {{ $t("wizard.progress") }}
        </span>
      </div>
      <div class="wizard-buttons">
        <v-btn
          :disabled="currentStep <= 0"
          @click="handlePreviousClick"
          class="back-button"
          variant="plain"
        >
          <img src="/src/assets/icons/arrow-back.svg" alt="Back" />
        </v-btn>
        <v-progress-linear
          v-model="progress"
          :height="18"
          color="#D3F073"
          bg-color="#F2F2F2"
          bg-opacity="1"
        />
        <v-btn
          :ref="buttonREF.next"
          @click="handleNextClick"
          :disabled="currentStep >= steps.length - 1 || !isStepCompleted"
          class="forward-button"
          variant="plain"
        >
          <img width="7" src="/src/assets/icons/arrow-back.svg" alt="Next" />
        </v-btn>
      </div>
      <v-window v-model="currentStep" variant="plain" :touch="false">
        <v-window-item v-for="(step, index) in steps" :key="step.id">
          <v-card>
            <WizardStep :step="step" />
          </v-card>
        </v-window-item>
      </v-window>
      <v-btn
        v-if="currentStep === steps.length - 1"
        :ref="buttonREF.save"
        @click="handleSave"
        :disabled="!isStepCompleted"
        class="mobile-save-button"
        variant="flat"
      >
        {{ $t("common.save") }}
      </v-btn>

      <v-btn
        v-else
        :ref="buttonREF.next"
        @click="handleNextClick"
        :disabled="currentStep >= steps.length - 1 || !isStepCompleted"
        class="mobile-save-button"
        variant="plain"
      >
        {{ $t("common.save") }}
      </v-btn>
    </template>

    <!-- Desktop view -->
    <template v-else>
      <div class="progress-bar">
        <span>
          {{ $t("wizard.progress") }}
        </span>
        <v-progress-linear
          v-model="progress"
          :height="10"
          color="#D3F073"
          bg-color="#F2F2F2"
          bg-opacity="1"
        />
      </div>
      <v-window class="desktop-window" v-model="currentStep" variant="plain">
        <v-window-item v-for="(step, index) in steps" :key="step.id">
          <v-card>
            <WizardStep :step="step" />
          </v-card>
        </v-window-item>
      </v-window>
      <div class="wizard-buttons">
        <v-btn
          v-if="currentStep > 0"
          @click="handlePreviousClick"
          class="back-button"
          variant="flat"
        >
          <img width="7" src="/src/assets/icons/arrow-back.svg" class="mr-2" alt="Back" />
          {{ $t("common.back") }}
        </v-btn>
        <v-btn
          v-if="currentStep < steps.length - 1"
          :ref="buttonREF.next"
          @click="handleNextClick"
          :disabled="!isStepCompleted"
          class="forward-button"
          variant="flat"
        >
          {{ $t("common.forward") }}
          <img
            v-if="isStepCompleted"
            width="7"
            src="/src/assets/icons/arrow-back-white.svg"
            class="ml-2"
            alt="Next"
          />
          <img v-else width="7" src="/src/assets/icons/arrow-back.svg" class="ml-2" alt="Next" />
        </v-btn>
        <v-btn
          v-if="currentStep === steps.length - 1"
          :ref="buttonREF.save"
          @click="handleSave"
          :disabled="!isStepCompleted"
          class="forward-button"
          variant="flat"
        >
          {{ $t("common.save") }}
        </v-btn>
      </div>
    </template>
  </div>
</template>

<style scoped lang="scss">
.v-card {
  border: none;
}
.wizard-container {
  background-color: white;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  transition: 0.3s ease-in-out;
  min-height: 10vh;
  overflow: auto;

  @media (min-width: 768px) {
    padding: 10px 20px;
    display: flex;
    gap: 16px;
  }

  .progress-bar {
    display: block;
    justify-content: space-evenly;
    align-items: center;

    @media (min-width: 768px) {
      display: flex;
    }

    span {
      font-size: 0.688rem;
      color: var(--eerie-black);
      margin-right: 20px;
    }
  }

  .wizard-buttons {
    display: flex;
    justify-content: end;
    gap: 16px;

    @media (max-width: 768px) {
      gap: 8px;
      margin-bottom: 42px;
    }

    .forward-button {
      background-color: var(--blue-bonnet) !important;
      color: white !important;
    }

    .forward-button:disabled {
      color: var(--dark-grey) !important;
      background-color: white !important;

      img {
        opacity: 0.5;
      }

      :deep(.v-btn__overlay) {
        background-color: white !important;
      }
    }

    .forward-button,
    .back-button {
      text-decoration: none;
      color: #858788;
      font-size: 0.8rem;

      @media (max-width: 768px) {
        margin-top: 2px;
        min-width: 14px !important;
        max-width: 14px !important;
        min-height: 14px !important;
        max-height: 12px !important;
        padding: 0 !important;
      }

      img {
        width: 5px;
      }

      &:hover {
        color: black;
      }
    }

    .forward-button {
      img {
        transform: rotate(180deg);
      }
    }
  }
}

.wizard-container::-webkit-resizer {
  content: "";
  background: url("/images/icons/vertical-resize.svg") no-repeat center center;
  background-size: 80%;
  background-color: var(--light-grey);
  border: 1px solid var(--light-grey);
}

.mobile-save-button {
  color: white;
  background: var(--cambridge-blue);
  margin: 25px 50px 50px;
  font-size: 1.125rem;
}

.desktop-window {
  overflow-y: auto;
}
</style>
