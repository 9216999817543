import convertNode from "../convertNode";
import showNodeConditionally from "../../showNodeConditionally";

export default function parseTableRow(xmlNode) {
  if (showNodeConditionally(xmlNode)) {
    return {
      type: "table_row",
      content: Array.from(xmlNode.children).map(convertNode),
    };
  } else {
    return null;
  }
}
