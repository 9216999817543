import vuetify from "./vuetify";
import router from "../router";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import store from "@/store/store";
import i18n from "@/plugins/i18n";
import InstantSearch from "vue-instantsearch/vue3/es";
import { VueRecaptchaPlugin } from "vue-recaptcha/head";
import setCustomConsole from "./customConsole";

setCustomConsole();

export function registerPlugins(app) {
  app
    .use(vuetify)
    .use(router)
    .use(store)
    .use(Toast)
    .use(i18n)
    .use(InstantSearch)
    .use(VueRecaptchaPlugin, {
      v2SiteKey: import.meta.env.VITE_GOOGLE_RECAPTCHA_SITEKEY,
    });
}
