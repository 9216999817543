const createFontSize = () => ({
  parseDOM: [{ tag: "span" }],
  toDOM: (node) => {
    const size = node.attrs.fontSize || 14;
    const style = `font-size:${size}px`;
    return ["span", { style }, 0];
  },
  attrs: {
    fontSize: { default: null },
  },
});

export default createFontSize;
