<script setup>
import FontTab from "@/components/ProseMirror/menu/views/FontTab.vue";
import FormatTab from "@/components/ProseMirror/menu/views/FormatTab.vue";
import ColorTab from "@/components/ProseMirror/menu/views/ColorTab.vue";
import SpacingTab from "@/components/ProseMirror/menu/views/SpacingTab.vue";
import { useI18n } from "vue-i18n";

const { tabs, activeTab, editorView } = defineProps(["tabs", "activeTab", "editorView"]);
const emit = defineEmits(["changeTab"]);
const { t } = useI18n();
</script>
<template>
  <div class="tab-content" v-if="activeTab">
    <div class="tab-content__container">
      <div class="tab-content__header">
        {{ t(`creator.toolbar.${activeTab.toLowerCase()}`).toUpperCase() }}

        <img
          class="tab-content__close-button"
          src="/images/icons/close.svg"
          alt="close button icon"
          @click="() => emit('changeTab', null)"
        />
      </div>

      <div class="tab-content__view">
        <FontTab v-if="activeTab === 'FONT'" :editorView="editorView" />
        <FormatTab v-if="activeTab === 'FORMAT'" :editorView="editorView" />
        <ColorTab v-if="activeTab === 'COLOR'" :editorView="editorView" />
        <SpacingTab v-if="activeTab === 'SPACING'" :editorView="editorView" />
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.tab-content {
  position: relative;
  height: 0;
  width: 100%;

  &__container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    background-color: white;
    padding: 0 10px;
    clip-path: inset(0px -10px -10px -10px);
    z-index: 10;
  }
  &__header {
    width: 100%;
    text-align: center;
    padding: 10px 0;
    font-weight: 600;
    margin-bottom: 10px;
  }
  &__close-button {
    position: absolute;
    top: 7px;
    right: 25px;
    cursor: pointer;
  }
}
</style>
