import NewDocument from "@/views/app/documents/NewDocument.vue";
import NewDocumentCreator from "@/views/app/documents/NewDocumentCreator.vue";
import Faq from "@/views/app/static_pages/Faq.vue";
import TermsOfService from "@/views/app/static_pages/TermsOfService.vue";
import AIDisclaimer from "@/views/app/static_pages/AIDisclaimer.vue";
import AboutUs from "@/views/app/static_pages/AboutUs.vue";
import CookiesPolicy from "@/views/app/static_pages/CookiesPolicy.vue";
import PrivacyPolicy from "@/views/app/static_pages/PrivacyPolicy.vue";
import UserProfile from "@/views/users/UserProfile.vue";
import DocumentList from "@/views/app/documents/DocumentList.vue";
import SignDocuments from "@/views/app/signatures/SignDocuments.vue";
import SignDocument from "@/views/app/signatures/SignDocument.vue";
import DocusignAuth from "@/views/app/docusign/DocusignAuth.vue";
import NotFound from "@/views/404NotFound.vue";
import Contact from "@/views/app/contact/Contact.vue";

const routes = [
  {
    path: "/",
    component: () => import("@/layouts/app/LayoutApp.vue"), //do not change to static imports - threatens to be cataclysmic on production
    children: [
      {
        path: "",
        redirect: "/new-document",
      },
      {
        path: "/profile",
        name: "profile",
        component: UserProfile,
        meta: { requiresAuthorization: true },
      },
      {
        path: "/new-document/:templateId?",
        name: "new-document",
        component: NewDocument,
        meta: { requiresAuthorization: false },
      },
      {
        path: "/documents/:documentId?",
        name: "documents",
        component: DocumentList,
        meta: { requiresAuthorization: false },
      },
      {
        path: "/documents/:uuid/share-history",
        name: "document-share-history",
        component: NewDocumentCreator,
        meta: { requiresAuthorization: false },
      },
      {
        path: "/new-document-creator/:uuid",
        name: "new-document-creator",
        component: NewDocumentCreator,
        meta: { requiresAuthorization: false },
      },
      {
        path: "/sign-documents",
        name: "sign-documents",
        component: SignDocuments,
        meta: { requiresAuthorization: true },
      },
      {
        path: "/sign-documents/:id",
        name: "sign-document",
        component: SignDocument,
        meta: { requiresAuthorization: true },
      },
      {
        path: "/terms-of-service",
        name: "terms-of-service",
        component: TermsOfService,
        meta: { requiresAuthorization: false },
      },
      {
        path: "/ai-disclaimer",
        name: "ai-disclaimer",
        component: AIDisclaimer,
        meta: { requiresAuthorization: false },
      },
      {
        path: "/about-us",
        name: "about-us",
        component: AboutUs,
        meta: { requiresAuthorization: false },
      },
      {
        path: "/frequently-asked-questions",
        name: "frequently-asked-questions",
        component: Faq,
        meta: { requiresAuthorization: false },
      },
      {
        path: "/privacy-policy",
        name: "privacy-policy",
        component: PrivacyPolicy,
        meta: { requiresAuthorization: false },
      },
      {
        path: "/cookies-policy",
        name: "cookies-policy",
        component: CookiesPolicy,
        meta: { requiresAuthorization: false },
      },
      {
        path: "/payment-success",
        name: "payment-success",
        component: DocumentList,
      },
      {
        path: "/docusign/auth",
        name: "docusign-auth",
        component: DocusignAuth,
      },
      {
        path: "/:pathMatch(.*)*",
        beforeEnter: (to, from, next) => {
          if (to.path.startsWith("/api")) {
            return;
          }
          next();
        },
        name: "Not Found",
        component: NotFound,
      },
      {
        path: "/contact",
        name: "contact",
        component: Contact,
        meta: { requiresAuthorization: false },
      },
    ],
  },
];

export default routes;
