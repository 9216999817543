<script setup>
import { defineProps, onMounted, ref, defineEmits } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const { open, showCloseButton = true } = defineProps(["open", "showCloseButton"]);
const emit = defineEmits(["close"]);

const isOpen = computed({
  get: () => open,
  set: (value) => {
    emit("close");
  },
});
</script>
<template>
  <VDialog v-model="isOpen" width="auto">
    <VCard>
      <div class="background-container">
        <div class="close-button">
          <img
            v-if="showCloseButton"
            @click="() => emit('close')"
            src="/images/icons/close.svg"
            alt="close dialog"
          />
        </div>

        <span class="dialog-title">
          {{ t("billing.payment_required") }}
        </span>
        <span class="dialog-sub-title">
          {{ t("billing.payment_subtitle") }}
        </span>

        <div class="plans-container" no-gutters>
          <slot></slot>
        </div>
      </div>
    </VCard>
  </VDialog>
</template>
<style scoped lang="scss">
.background-container {
  background: transparent url("@/assets/backgrounds/plan-background.png") 47% 0 no-repeat
    padding-box;

  @media (max-width: 768px) {
    background: var(--white) !important;
  }
}

.plans-container {
  display: flex;
  gap: 50px;
  padding: 0 55px;
  position: relative;

  border-image: linear-gradient(to left, var(--cambridge-blue), var(--booger-buster)) 30;
  border-width: 17px;
  border-style: solid;
  border-top: 0;
  border-left: 0;
  border-right: 0;

  @media (max-width: 768px) {
    display: block;
    padding: 0 20px 50px;
  }
}

.dialog-title {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-left: 60px;
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--eerie-black);
  margin-top: 20px;

  @media (max-width: 768px) {
    margin-left: 20px;
  }
}

.dialog-sub-title {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-left: 60px;
  font-size: 0.688rem;
  color: var(--dark-grey);
  margin-bottom: 40px;

  @media (max-width: 768px) {
    margin-left: 20px;
  }
}

.close-button {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
  margin-right: 5px;

  img {
    cursor: pointer;
    transition: 0.2s ease;
    &:hover {
      opacity: 0.8;
    }
  }
}

@media (max-width: 768px) {
  :deep(.v-overlay__content) {
    min-width: 100% !important;
    max-width: 100% !important;
    margin: 0 !important;
    margin-top: 75px !important;
    height: 100% !important;
  }
}
</style>
