<script setup>
import { useRouter } from "vue-router";

const props = defineProps(["isDialogOpen", "user"]);
const emit = defineEmits(["closeDialog"]);

const router = useRouter();
</script>

<template>
  <v-dialog v-model="props.isDialogOpen" persistent max-width="600">
    <v-card>
      <div class="close-container">
        <img @click="emit('closeDialog')" src="/images/icons/close.svg" alt="close dialog" />
      </div>
      <v-card-title class="dialog-title">
        {{ $t("email_popup.title") }}
      </v-card-title>
      <v-card-text class="dialog-content">
        {{ $t("email_popup.description") }}

        <br />
        <v-btn
          class="mt-5"
          color="primary"
          :text="$t('email_popup.button')"
          variant="flat"
          @click="router.push('/register')"
        ></v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">
.dialog-title {
  font-size: 1.5rem;
  font-weight: bold;
  padding: 23px 23px 0px;
  text-align: center;
}

.dialog-content {
  color: #858788;
  text-align: center;
}

.close-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-right: 20px;

  img {
    cursor: pointer;
  }
}
</style>
