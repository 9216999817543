<script setup>
import axios from "axios";
import { useToast } from "vue-toastification";
import { defineProps, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useIsMobile } from "@/utils/checkMobile";

const isMobile = useIsMobile();
const { userData } = defineProps(["userData"]);
const $t = useI18n().t;
const confirmDialog = ref(false);
const invoice = ref({
  street: "",
  city: "",
  zip_code: "",
  country: "",
  tin_number: "",
});

const errors = ref({
  street: "",
  city: "",
  zip_code: "",
  country: "",
  tin_number: "",
});

const saveInvoiceDetails = async () => {
  confirmDialog.value = false;
  await axios
    .put("/api/user", {
      ...userData,
      ...invoice.value,
    })
    .then(() => {
      errors.value = {};
      useToast().success($t("notifications.default_update_success"));
    })
    .catch((error) => {
      errors.value = error.response.data.errors;
      useToast().error(error.response.data.message);
    });
};

onMounted(() => {
  invoice.value = {
    street: userData.street,
    city: userData.city,
    zip_code: userData.zip_code,
    country: userData.country,
    tin_number: userData.tin_number,
  };
});
</script>
<template>
  <VForm class="box" @submit.prevent="saveInvoiceDetails">
    <div>
      <h4 v-if="!isMobile" class="header">
        {{ $t("profile.invoice_details") }}
      </h4>
      <VRow dense>
        <VCol cols="12">
          <label class="input-label" for="street">
            {{ $t("invoice.address") }}
          </label>
          <VTextField
            v-model="invoice.street"
            :error-messages="errors.street"
            id="street"
            variant="outlined"
            density="compact"
            base-color="#E1E1E1"
          />
        </VCol>
        <VCol cols="12">
          <label class="input-label" for="city">
            {{ $t("invoice.city") }}
          </label>
          <VTextField
            v-model="invoice.city"
            :error-messages="errors.city"
            id="city"
            variant="outlined"
            density="compact"
            base-color="#E1E1E1"
          />
        </VCol>
        <VCol cols="12">
          <label class="input-label" for="zip_code">
            {{ $t("invoice.zip_code") }}
          </label>
          <VTextField
            v-model="invoice.zip_code"
            :error-messages="errors.zip_code"
            id="zip_code"
            variant="outlined"
            density="compact"
            base-color="#E1E1E1"
          />
        </VCol>
        <VCol cols="12">
          <label class="input-label" for="country">
            {{ $t("invoice.country") }}
          </label>
          <VTextField
            v-model="invoice.country"
            :error-messages="errors.country"
            id="country"
            variant="outlined"
            density="compact"
            base-color="#E1E1E1"
          />
        </VCol>
        <VCol cols="12">
          <label class="input-label" for="tin_number">
            {{ $t("invoice.tin_number") }}
          </label>
          <VTextField
            v-model="invoice.tin_number"
            :error-messages="errors.tin_number"
            id="tin_number"
            variant="outlined"
            density="compact"
            base-color="#E1E1E1"
          />
        </VCol>
      </VRow>
    </div>
    <div class="button-container">
      <VBtn
        @click="confirmDialog = !confirmDialog"
        type="button"
        class="form-button"
        variant="flat"
      >
        {{ $t("common.save") }}
      </VBtn>
    </div>
  </VForm>
  <VDialog v-model="confirmDialog" max-width="400">
    <VCard>
      <div class="close-container">
        <img @click="confirmDialog = false" src="/images/icons/close.svg" alt="close dialog" />
      </div>
      <span class="dialog-title">
        {{ $t("common.confirm_changes") }}
      </span>
      <div class="dialog-actions-container">
        <VBtn variant="tonal" class="form-button" @click="saveInvoiceDetails">
          {{ $t("common.confirm") }}
        </VBtn>
      </div>
    </VCard>
  </VDialog>
</template>
<style scoped lang="scss">
.header {
  margin-bottom: 30px;
}

.form-button {
  font-size: 1.125rem;
  color: white !important;
  background-color: var(--cambridge-blue);
  text-transform: none;
  border-radius: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 145px;
  height: 45px;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
}

.button-container {
  display: flex;
  justify-content: flex-end;
}
.dialog-actions-container {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}
.dialog-title {
  display: flex;
  width: 100%;
  justify-content: center;
  font-weight: bold;
  color: var(--eerie-black);
  margin-top: 20px;
  margin-bottom: 40px;
  font-size: 1.5rem;
  text-align: center;
}
.close-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-right: 20px;
  img {
    cursor: pointer;
  }
}
.input-label {
  color: var(--eerie-black);

  @media (max-width: 768px) {
    font-size: 0.875rem;
  }
}

@media (max-width: 768px) {
  :deep(.v-overlay__content) {
    width: 100% !important;
    max-width: 100% !important;
  }
}
</style>
