<script setup>
import { changeAlign } from "@/components/ProseMirror/menu/factories/createAlignItem";
import checkAlign from "@/components/ProseMirror/menu/utils/checkAlign";

const { editorView } = defineProps(["editorView"]);

const alignTypes = ["left", "center", "right"];

const localState = reactive({
  left: true,
  center: false,
  right: false,
});

const handleClick = (type) => {
  changeAlign(type)(editorView.state, editorView.dispatch);
};

const updateLocalState = () => {
  localState.left = checkAlign(editorView.state, "left");
  localState.center = checkAlign(editorView.state, "center");
  localState.right = checkAlign(editorView.state, "right");

  if (!localState.center && !localState.right) {
    localState.left = true;
  }
};

onMounted(() => {
  updateLocalState();
});
</script>
<template>
  <div class="tab-content__items">
    <div class="tab-content__marks">
      <div
        v-for="alignType in alignTypes"
        :class="{
          active: localState[alignType],
          'tab-content__item': true,
        }"
        @click="() => handleClick(alignType)"
      >
        <div
          class="mark__icon"
          :style="`mask: url(/images/icons/editor-toolbar/align-${alignType}.svg) no-repeat center`"
        ></div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.tab-content {
  &__items {
    height: 100%;
    padding: 0 15px;

    .tab-content__marks {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 15px;
    }

    .tab-content__item {
      border: 1px solid var(--grey);
      padding: 0px 15px;
      font-size: 1.25rem;
      flex: 1;
      display: flex;
      justify-content: center;
      transition: 0.2s ease;
      &:hover {
        cursor: pointer;
        background-color: var(--light-grey);
      }

      .mark__icon {
        width: 30px;
        height: 30px;
        background-color: var(--dark-grey);
      }

      &.active {
        .mark__icon {
          background-color: var(--blue-bonnet);
        }
      }
    }
  }
}
</style>
