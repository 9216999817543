export default function checkMarkActive(state, type) {
  const { from, $from, to, empty } = state.selection;

  // Helper function to find a specific mark by name in a set of marks
  const findMarkByName = (marks, markName) => {
    return marks.find((mark) => mark.type.name === markName);
  };

  const isMarkWithVariousStates =
    type.name === "fontSize" ||
    type.name === "fontFamily" ||
    type.name === "textColor" ||
    type.name === "backgroundColor";

  if (isMarkWithVariousStates) {
    let markFound = null;

    // Check across the selection range if it's not empty
    if (!empty) {
      state.doc.nodesBetween(from, to, (node) => {
        if (node.isText) {
          const mark = findMarkByName(node.marks, type.name);
          if (mark) {
            markFound = mark; // Found the mark, set it
          }
        }
      });
    }

    // If selection is empty, check the current cursor position
    if (!markFound && empty) {
      const marks = $from.marks();
      markFound = findMarkByName(marks, type.name);
    }

    // Return the mark's attributes if found, otherwise return false
    if (markFound) {
      return markFound.attrs[type.name];
    } else {
      return false;
    }
  }

  // Check for other types of marks
  if (empty) {
    return !!type.isInSet(state.storedMarks || $from.marks());
  } else {
    return state.doc.rangeHasMark(from, to, type);
  }
}
