<script setup>
import { computed, onMounted, ref, toRaw } from "vue";
import axios from "axios";
import { EditorView } from "prosemirror-view";
import { EditorState, Plugin } from "prosemirror-state";
import { Node } from "prosemirror-model";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useToast } from "vue-toastification";

import Page from "@/components/Page.vue";
import DocumentNavigator from "@/components/documents/navigator/DocumentNavigator.vue";
import defaultSchema from "@/components/ProseMirror/schemas/defaultSchema";
import { useIsMobile } from "@/utils/checkMobile";
import store from "@/store/store";
import PageInfo from "@/components/documents/approval_module/PageInfo.vue";
import NewCommentDropdownMenu from "@/components/documents/approval_module/NewCommentDropdownMenu.vue";
import BackButton from "@/components/BackButton.vue";

const { t } = useI18n();
const toast = useToast();
const router = useRouter();
const documentId = router.currentRoute.value.params.uuid;
const documentToken = router.currentRoute.value.query.token;
const isMobile = useIsMobile();
const isCommentDrawerVisible = ref(!isMobile);
const commentListREF = ref(null);
const document = ref(null);
const documentPreviewRef = ref(null);
const isDocumentAccepted = ref(false);
const isDocumentRead = ref(false);

const newComment = ref({
  content: "",
  from: null,
  to: null,
});
const comments = ref([]);
const backgroundMarks = ref([]);
const mySchema = defaultSchema;
let documentPreviewView = null;
const editorScrollTop = ref(0);
const editorScrollPosition = ref(0);
const newCommentInputREF = ref(null);
const dropdownVisible = ref(false);
const dropdownPosition = ref({ top: 0, left: 0, right: 0 });
const dropdownStyle = computed(() => ({
  top: `${
    dropdownPosition.value.top - 75 - (editorScrollTop.value - editorScrollPosition.value)
  }px`,
  left: `${
    dropdownPosition.value.left +
    ((dropdownPosition.value.right - dropdownPosition.value.left) / 2 - 38)
  }px`,
}));
const selectedComment = ref(null);
const user = store.state.auth.user;

const isTemporary = computed(() => user.isTemporary);

// Fetch document data
const getDocument = async () => {
  try {
    const response = await axios.get(`api/documents/${documentId}/approve?token=${documentToken}`);
    document.value = response.data.data;
  } catch (error) {
    console.error(error);
  }
};
const isAuthUserComment = (comment) => {
  return comment.user.email === user.email;
};

const focusCommentInput = () => {
  isCommentDrawerVisible.value = true;

  newCommentInputREF.value.focus();

  removeBackgroundMarks();
  addBackgroundMark(newComment.value.from, newComment.value.to);
};

const selectComment = (comment) => {
  const { from, to } = comment;

  selectedComment.value = comment.id;
  removeBackgroundMarks();
  addBackgroundMark(from, to);

  const element = documentPreviewView.domAtPos(from).node;
  element.scrollIntoView({ behavior: "smooth", block: "center" });

  // commentListREF.value.querySelector is not a function
  const commentElement = commentListREF.value.querySelector(`[data-comment-id="${comment.id}"]`);
  commentElement.scrollIntoView({
    behavior: "smooth",
    block: "center",
  });

  // Open the comment drawer if the comment is selected in editor from the mobile
  if (isMobile) {
    isCommentDrawerVisible.value = true;
  }
};

// Fetch comments
const getComments = async () => {
  let nextPageUrl = `/api/documents/${documentId}/comments?page=1`;

  try {
    while (nextPageUrl) {
      const response = await axios.get(nextPageUrl + `&token=${documentToken}`);
      const commentData = response.data;

      comments.value = [...comments.value, ...commentData.data];
      nextPageUrl = commentData.next_page_url;
      markComments();
    }
  } catch (error) {
    toast.error(t("notifications.default_error"));
  }
};

// Mark comments in the document preview
const markComments = () => {
  comments.value.forEach(({ from, to, content }) => {
    addCommentMark(from, to, content);
  });
};

const removeBackgroundMarks = () => {
  backgroundMarks.value.forEach(({ from, to, content }) => {
    removeBackgroundMark(from, to, content);
  });
};

// Add a mark (comment) to the document
const addCommentMark = (from, to, comment) => {
  const tr = documentPreviewView.state.tr.addMark(
    from,
    to,
    mySchema.marks.comment.create({ comment }),
  );
  documentPreviewView.focus();
  documentPreviewView.dispatch(tr);
};

// Remove a mark (comment) from the document
const removeCommentMark = (from, to) => {
  const tr = documentPreviewView.state.tr.removeMark(from, to, mySchema.marks.comment);
  documentPreviewView.focus();
  documentPreviewView.dispatch(tr);
};

const addBackgroundMark = (from, to) => {
  const tr = documentPreviewView.state.tr.addMark(
    from,
    to,
    mySchema.marks.backgroundColor.create({ backgroundColor: "orange" }),
  );
  documentPreviewView.focus();
  documentPreviewView.dispatch(tr);
  backgroundMarks.value.push({ from, to });
};

const removeBackgroundMark = (from, to) => {
  const tr = documentPreviewView.state.tr.removeMark(from, to, mySchema.marks.backgroundColor);
  documentPreviewView.focus();
  documentPreviewView.dispatch(tr);
  backgroundMarks.value = backgroundMarks.value.filter(
    (mark) => mark.from !== from && mark.to !== to,
  );
};

const scrollCommentListToBottom = () => {
  nextTick(() => {
    commentListREF.value.scrollTo({
      top: commentListREF.value.scrollHeight,
      behavior: "smooth",
    });
  });
};

// Add a new comment
const addComment = async () => {
  try {
    const response = await axios.post(
      `/api/documents/${documentId}/comments?token=${documentToken}`,
      newComment.value,
    );
    comments.value.push(response.data.data);

    scrollCommentListToBottom();

    toast.success(t("comments.comment_added"));

    //Add to editor
    try {
      addCommentMark(newComment.value.from, newComment.value.to, newComment.value.content);

      // Close the drawer if the comment is added from the mobile
      if (newComment.value.from !== null && newComment.value.to !== null && isMobile) {
        isCommentDrawerVisible.value = false;
      }

      removeBackgroundMark(newComment.value.from, newComment.value.to);
      newComment.value = {
        content: "",
        from: null,
        to: null,
      };
    } catch (error) {
      console.error("ProseMirror error:", error);
    }
  } catch (error) {
    toast.error(t("notifications.default_error"));
  }
};

// Delete a comment
const deleteComment = async (commentId) => {
  try {
    await axios.delete(`/api/documents/${documentId}/comments/${commentId}?token=${documentToken}`);
    toast.success(t("comments.comment_removed"));

    const { from, to } = comments.value.find((comment) => comment.id === commentId);
    comments.value = comments.value.filter((comment) => comment.id !== commentId);

    // Remove from editor
    try {
      removeCommentMark(from, to);
      removeBackgroundMark(from, to);
    } catch (error) {
      console.error("ProseMirror error:", error);
    }
  } catch (error) {
    toast.error(t("comments.comment_removed_error"));
  }
};

// Approve the document
const approveDocument = async () => {
  try {
    await axios.post(`api/documents/${documentId}/approve`, {
      token: documentToken,
    });
    isDocumentAccepted.value = true;
    toast.success(t("documents.document_accepted"));
  } catch (error) {
    toast.error(t("notifications.default_error"));
  }
};

// Show dropdown over selected text
const showDropdown = (coordsFrom, coorsTo) => {
  editorScrollPosition.value = editorScrollTop.value;
  dropdownPosition.value = {
    top: coordsFrom.top,
    left: coordsFrom.left,
    right: coorsTo.right,
  };
  dropdownVisible.value = true;
};

// Scroll detection for document reading
const watchDocumentPreviewScroll = () => {
  const preview = documentPreviewRef.value.firstChild;

  preview.addEventListener("scroll", () => {
    const { scrollHeight, scrollTop, clientHeight } = preview;

    //sometimes the scrollHeight is a bit less than the actual height of the document so we add a buffer
    const buffer = 100;

    if (scrollTop + clientHeight >= scrollHeight - buffer) {
      isDocumentRead.value = true;
    }

    editorScrollTop.value = scrollTop;
  });
};

// Activate the accept button if the document does not have a scrollbar
const ifDocumentIsShortEnableAcceptButton = () => {
  const preview = documentPreviewRef.value.firstChild;
  const { scrollHeight, clientHeight } = preview;

  if (scrollHeight <= clientHeight) {
    isDocumentRead.value = true;
  }
};

// ProseMirror comment plugin
const commentPlugin = new Plugin({
  props: {
    handleDOMEvents: {
      mouseup(view) {
        const { from, to } = view.state.selection;
        if (from === to) return false;

        if (view.state.doc.rangeHasMark(from, to, mySchema.marks.comment)) return;

        const coordsFrom = view.coordsAtPos(from);
        const coordsTo = view.coordsAtPos(to);
        showDropdown(coordsFrom, coordsTo);
        newComment.value.from = from;
        newComment.value.to = to;
        return true;
      },
      click(view) {
        const { from, to } = view.state.selection;

        if (view.state.doc.rangeHasMark(from - 1, to, mySchema.marks.comment)) {
          comments.value.forEach((comment) => {
            if (comment.from <= from && comment.to >= to) {
              selectComment(comment);
            }
          });
        }
      },
    },
  },
});

// On component mount
onMounted(async () => {
  await getDocument();

  try {
    JSON.parse(document.value.content);
  } catch (error) {
    toast.error(t("notifications.default_error"));
    return router.push("/documents");
  }

  documentPreviewView = new EditorView(documentPreviewRef.value, {
    state: EditorState.create({
      doc: Node.fromJSON(mySchema, toRaw(JSON.parse(document.value.content))),
      plugins: [commentPlugin],
    }),
    editable: () => false,
  });

  await getComments();
  watchDocumentPreviewScroll();

  ifDocumentIsShortEnableAcceptButton();
});
</script>

<template>
  <Page v-if="document" :title="document.name" class="page">
    <!-- Mobile title actions -->
    <template v-if="isMobile" #title-actions>
      <VBtn
        @click="isCommentDrawerVisible = !isCommentDrawerVisible"
        class="comments-show-button"
        variant="flat"
      >
        <img src="/images/icons/add-comment.svg" alt="Comments" />
      </VBtn>
      <DocumentNavigator
        :document="document"
        :has-wizard-completed="true"
        :buttons="['visitor']"
        @show-history-modal="toggleHistoryModal"
      />
    </template>

    <template #back-button>
      <BackButton :to="isTemporary ? '/login' : '/documents'" />
    </template>

    <template #default>
      <PageInfo :document="document" :documentId="documentId" />

      <!-- Document preview -->
      <div class="document-preview" ref="documentPreviewRef" id="documentPreview"></div>

      <!-- Vuetify dropdown (menu) -->
      <NewCommentDropdownMenu
        v-model="dropdownVisible"
        :style="dropdownStyle"
        @add-comment="focusCommentInput"
      />

      <!-- Approve document button -->
      <v-btn
        :disabled="isDocumentAccepted || !isDocumentRead"
        @click="approveDocument"
        variant="flat"
        class="form-button"
      >
        {{ $t("documents.accept_document") }}
      </v-btn>

      <!-- Comments navigation drawer -->
      <v-navigation-drawer
        v-model="isCommentDrawerVisible"
        location="right"
        :width="!isMobile ? '400' : '360'"
        :permanent="!isMobile"
      >
        <template v-slot:prepend>
          <div class="comments-header px-4 pt-8 pb-5">
            <h3>{{ $t("comments.title") }}</h3>

            <v-btn
              v-if="isMobile"
              variant="flat"
              @click="isCommentDrawerVisible = false"
              class="comments-close-button"
              icon
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <v-divider thickness="2"></v-divider>
        </template>
        <template #default>
          <div class="comments-wrapper">
            <div ref="commentListREF" class="comments-content">
              <v-list lines="three">
                <v-list-item
                  :data-comment-id="comment.id"
                  v-for="comment in comments"
                  :key="comment.id"
                  @click="selectComment(comment)"
                  :class="{
                    'selected-comment': selectedComment === comment.id,
                    disabled: comment.from === null,
                  }"
                >
                  <v-list-item-title>{{ comment.content }}</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ comment.user.name }}
                    <br />
                    <small>{{ comment.created_at }}</small>
                  </v-list-item-subtitle>
                  <template #prepend>
                    <img
                      :src="
                        isAuthUserComment(comment)
                          ? '/images/icons/user.svg'
                          : '/images/icons/user-disabled.svg'
                      "
                      alt="user"
                      width="20"
                      class="mr-5"
                    />
                  </template>
                  <template #append>
                    <v-icon
                      v-if="isAuthUserComment(comment)"
                      color="black"
                      @click="deleteComment(comment.id)"
                      size="20"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                </v-list-item>
              </v-list>
            </div>
            <div class="comment-input">
              <v-textarea
                variant="solo"
                density="compact"
                ref="newCommentInputREF"
                v-model="newComment.content"
                :placeholder="$t('comments.comment_placeholder')"
                hide-details="true"
                no-resize
                rows="2"
                auto-grow
                max-rows="5"
              />
              <v-btn @click="addComment" class="form-button">
                {{ $t("comments.add_comment") }}
              </v-btn>
            </div>
          </div>
        </template>
      </v-navigation-drawer>
    </template>
  </Page>
</template>

<style scoped lang="scss">
.page {
  padding-bottom: 15px !important;
  max-height: 100vh;
}

.document-preview {
  border: 1px solid;
  border-color: rgba(var(--v-border-color), var(--v-border-opacity));
  padding: 25px 20px 50px;
  overflow-y: auto;
  z-index: 1;
  position: relative;

  @media (max-height: 530px) {
    padding: 10px;
  }
}

:deep(.ProseMirror) {
  /* Custom thin scrollbar */

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

:deep(h4) {
  justify-content: space-between;
}

:deep(.comment) {
  background-color: yellow;
  cursor: pointer;
}

.form-button {
  font-size: 1.125rem;
  color: white !important;
  background-color: var(--cambridge-blue);
  text-transform: none;
  border-radius: 0;
  padding-top: 8px;
  padding-bottom: 20px;
}

.comments-drawer {
  /* max-height: 100vh !important; */
}
.comments-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 15px;
}

.comments-content {
  overflow-y: auto;
  flex: 1;
}

.comment-input {
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--light-grey);
}

.selected-comment {
  background-color: #fff6e5;
}

:deep(.v-list-item-title) {
  white-space: normal;
  margin-bottom: 5px;
}

.disabled {
  cursor: default !important;
  background-color: white !important;
}

.comments-show-button {
  min-width: 44px !important;
  max-width: 44px !important;
  min-height: 42px !important;
  max-height: 42px !important;
  border: 1px solid var(--grey) !important;
  background-color: white !important;

  img {
    width: 30px;
  }
}

.comments-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.comments-close-button {
  width: 44px;
  height: 44px;

  border: 1px solid var(--grey) !important;
  background-color: white !important;
  /* position: absolute; */
  /* top: 10px;
  right: 10px; */
}

:deep(.navigator) {
  margin-right: 0;
}
</style>
