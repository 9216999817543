<script setup>
import axios from "axios";
import { onMounted, ref } from "vue";

const isLoaded = ref(false);

const expanded = ref([]);
const invoices = ref([]);
const headers = ref([
  {
    title: "ID",
    key: "id",
    align: "start",
    sortable: false,
    width: "20%",
  },
  {
    title: "Type",
    key: "description",
    align: "start",
    sortable: false,
    width: "30%",
  },
  {
    title: "Payment Date",
    key: "date",
    align: "start",
    sortable: false,
    width: "30%",
  },
]);

const getPaymentHistory = async () => {
  const response = await axios.get("/api/user/subscription/history");
  invoices.value = response.data.data;
  isLoaded.value = true;
};

const handleDownloadInvoice = async (invoiceId) => {
  const response = await axios.get("api/user/invoices/" + invoiceId, {
    responseType: "blob",
  });
  const file = new File([response.data], "invoice.pdf", {
    type: "application/pdf",
  });
  const url = URL.createObjectURL(file);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", file.name);
  document.body.appendChild(link);
  link.click();
};

onMounted(async () => {
  await getPaymentHistory();
});
</script>

<template>
  <v-data-table-virtual
    v-model:expanded="expanded"
    :headers="headers"
    :items="invoices"
    item-value="name"
    show-expand
    disable-sort
  >
    <template v-slot:expanded-row="{ columns, item }">
      <tr>
        <td>
          <img class="download-icon" src="/images/icons/paid-document.png" alt="Invoice icon" />
        </td>
        <td :colspan="2">{{ $t("common.amount") }} {{ item.total }}</td>
        <td align="center">
          <img
            @click="emit('download')"
            class="download-icon"
            src="/images/icons/download.svg"
            alt="Download invoice"
          />
        </td>
      </tr>
    </template>
  </v-data-table-virtual>
</template>

<style scoped lang="scss">
:deep(.v-table__wrapper) {
  border: 1px solid var(--grey) !important;
  box-shadow: 0pt 3pt 6pt var(--grey);
}

:deep(.v-data-table__th) {
  width: 100px !important;
  text-wrap: nowrap !important;
}

:deep(.v-data-table__td) {
  word-break: break-all !important;
  border-right: 1px solid var(--grey);
}

:deep(.v-data-table__th:nth-child(3)),
:deep(.v-data-table__td:nth-child(3)) {
  border-right: none;
}

.download-icon {
  cursor: pointer;
  width: 12px !important;
  margin-right: 5px;
}
</style>
