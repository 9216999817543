<script setup>
const { style } = defineProps(["style"]);
const emit = defineEmits(["add-comment"]);
const model = defineModel();

const handleClickAddComment = () => {
  emit("add-comment");
};
</script>
<template>
  <v-menu class="dropdown" v-model="model" :style="style">
    <v-list class="py-0">
      <v-list-item class="pt-3 dropdown-background" @click="handleClickAddComment">
        <img width="39.37" src="/images/icons/add-comment.svg" alt="Comment" />
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<style scoped lang="scss">
.dropdown {
  position: absolute;
  z-index: 2;
}

.dropdown-background {
  background-color: var(--light-grey);
  color: white;
  padding: 10px;
}
</style>
