export function checkSpacing(state, type) {
  const { from, to } = state.selection;
  let active = false;
  state.doc.nodesBetween(from, to, (node) => {
    if (node.attrs.lineHeight) {
      active = node.attrs.lineHeight;
    }
  });
  return active;
}

export function changeSpacing(spacing) {
  return function (state, dispatch) {
    const { $from, $to } = state.selection;
    const attrs = { lineHeight: spacing };
    let tr = state.tr;
    state.doc.nodesBetween($from.pos, $to.pos, (node, pos) => {
      if (node.type === state.schema.nodes.paragraph) {
        tr = tr.setNodeMarkup(pos, null, {
          ...node.attrs,
          lineHeight: spacing,
        });
      }
    });
    if (dispatch) dispatch(tr.scrollIntoView());
    return true;
  };
}
