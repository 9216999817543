<script setup>
import { useStore } from "vuex";

const emit = defineEmits(["clickButton"]);
const store = useStore();

const buttons = computed(() => store.state.chat.buttons);
const handleClickButton = (clickedButton) => {
  emit("clickButton", clickedButton);
  store.commit("chat/resetButtons");
};
</script>
<template>
  <div class="sample-messages">
    <template v-for="button in buttons" :key="button.title">
      <div v-if="button.title" class="message" @click="() => handleClickButton(button)">
        {{ button.title }}
      </div>
    </template>
  </div>
</template>
<style scoped lang="scss">
.sample-messages {
  display: flex;
  gap: 10px 15px;
  flex-wrap: wrap;

  .message {
    border: 1px solid var(--grey);
    color: #858788;
    font-size: 0.875rem;
    padding: 12px;
    text-wrap: nowrap;
    user-select: none;
    cursor: pointer;
    transition: 0.3s ease-in-out;

    &:hover {
      background-color: var(--light-grey);
      color: var(--eerie-black);
    }
  }
}
</style>
