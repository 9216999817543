import { createStore } from "vuex";
import authModule from "./modules/auth";
import editorModule from "./modules/editor";
import sharedModule from "./modules/shared";
import chatModule from "./modules/chat";
import createPersistedState from "vuex-persistedstate";

const store = createStore({
  modules: {
    auth: authModule,
    chat: chatModule,
    editor: editorModule,
    shared: sharedModule,
  },
  plugins: [
    createPersistedState({
      paths: ["auth", "chat"],
    }),
  ],
});

export default store;
