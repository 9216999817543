<script setup>
import { useI18n } from "vue-i18n";

const { tm } = useI18n();

const emit = defineEmits(["clickMessage"]);

const handleClickMessage = (clickedMessage) => {
  emit("clickMessage", clickedMessage);
};
</script>
<template>
  <div class="sample-messages">
    <div
      v-for="message in tm('chat.sample_messages')"
      class="message"
      @click="() => handleClickMessage(message)"
    >
      {{ message }}
    </div>
  </div>
</template>
<style scoped lang="scss">
.sample-messages {
  display: flex;
  gap: 10px 15px;
  flex-wrap: wrap;

  .message {
    border: 1px solid var(--grey);
    color: #858788;
    font-size: 0.875rem;
    padding: 12px;
    text-wrap: nowrap;
    user-select: none;
    cursor: pointer;
    transition: 0.3s ease-in-out;

    &:hover {
      background-color: var(--light-grey);
      color: var(--eerie-black);
    }
  }
}
</style>
