// Composables
import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes";
import routesAuth from "./routesAuth";
import store from "@/store/store";
import { useToast } from "vue-toastification";
import axios from "axios";
import useCookies from "@/utils/cookies";
import routesMobile from "@/router/routesMobile";
import routesVisitor from "@/router/routesVisitor";
import setUserData from "@/utils/setUserData";
import { computed } from "vue";

const cookies = useCookies();

const isLoggedIn = computed(() => store.state.auth.isLoggedIn);
const isTemporary = computed(() => store.state.auth.user.isTemporary);
const isEmailVerified = computed(() => store.state.auth.user.isEmailVerified);

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [...routes, ...routesAuth, ...routesMobile, ...routesVisitor],
});

const isAuthRoute = (pathName) => {
  return routesAuth.some((route) => route.name === pathName);
};

router.beforeEach(async (to, from, next) => {
  const requiresAuthorization = to.matched.some((record) => record.meta.requiresAuthorization);

  if (!cookies.get("apiToken")) {
    await createTemporaryUser();
  }

  if (isLoggedIn.value || cookies.get("apiToken")) {
    await setUserData();
  }

  if (requiresAuthorization && isTemporary.value && !isAuthRoute(to.name)) {
    useToast().error("You must log in to view this page.");
    next({
      path: "/login",
      query: { redirect: to.fullPath },
    });
  }

  if (isLoggedIn.value && !isTemporary.value && !isEmailVerified.value && !isAuthRoute(to.name)) {
    next({
      path: "/verification-required",
    });
  }

  if (isLoggedIn.value && !isTemporary.value && isEmailVerified.value && isAuthRoute(to.name)) {
    next({ path: "/new-document" });
  }

  next();
});

const createTemporaryUser = async () => {
  try {
    const response = await axios.post("/api/temporary-user");
    store.commit("auth/login", response.data.token);
    cookies.set("apiToken", response.data.token, 7);
  } catch (error) {
    console.error("Error creating temporary user:", error);
  }
};

export default router;
