import Echo from "laravel-echo";
import Pusher from "pusher-js";

window.addEventListener("DOMContentLoaded", function () {
  window.Pusher = Pusher;
  window.Echo = new Echo({
    broadcaster: "reverb",
    namespace: "App.Events.NotificationEvents",
    key: import.meta.env.VITE_REVERB_APP_KEY,
    wsHost: import.meta.env.VITE_REVERB_HOST,
    wsPort: import.meta.env.VITE_REVERB_PORT,
    wssPort: import.meta.env.VITE_REVERB_PORT,
    forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? "https") === "https",
    enabledTransports: ["ws", "wss"],
    authEndpoint: `${import.meta.env.VITE_APP_API_BASE_URL}/broadcasting/auth`,
    auth: {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("apiToken"),
        "Access-Control-Allow-Origin": "*",
      },
    },
  });
});
