const createOrderedList = (nodes) => ({
  ...nodes.get("ordered_list"),
  toDOM(node) {
    return [
      "ol",
      {
        "data-id": node.attrs.id || null,
        start: node.attrs.order || null,
        type: node.attrs.type || "1",
        class: node.attrs.class || null,
      },
      0,
    ];
  },
  attrs: {
    id: { default: null },
    order: { default: "1" },
    type: { default: "1" },
    class: { default: null },
  },
});

export default createOrderedList;
