import { registerPlugins } from "@/plugins";
import "vue-global-api";
import { createApp } from "vue";
import App from "./App.vue";
import vClickOutside from "./utils/directives/v-click-outside";
import { sessionTimer } from "./plugins/session-timer";
import initSentry from "./plugins/sentry";
// eslint-disable-next-line no-unused-vars
import "@/plugins/axios-config.js";
import "@/plugins/pusher";
import "@/plugins/notifications";

import router from "./router";

const app = createApp(App);

initSentry(app, router);

registerPlugins(app);

app.directive("click-outside", vClickOutside);

app.mount("#app");

const timer = sessionTimer();
timer.startTimer();
