import store from "@/store/store";
import convertNode from "../convertNode";

export default function optionDecider(options, type = null) {
  const wizardAnswers = store.state.editor.wizard.answers;

  for (const option of options) {
    //Get switch condition
    const condition = Array.from(option.children).find((child) => child.nodeName === "condition");

    //Get question
    const question = Array.from(condition.children).find((child) => child.nodeName === "question");
    const questionId = question.getAttribute("id");

    //Get answer
    const answer = wizardAnswers.find((answer) => answer.questionId === questionId);
    if (!answer) {
      return null;
    }

    if (question.getAttribute("value") == answer.answer) {
      const value = Array.from(option.children).find((child) => child.nodeName === "value");

      if (type && !value.getAttribute("type")) {
        value.setAttribute("type", type);
      }

      const valueNode = convertNode(value);

      if (valueNode.content.length === 0) {
        return null;
      }

      return valueNode;
    }
  }
  return null;
}
