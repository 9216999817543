<script setup lang="ts">
const { template } = defineProps<{
  template;
}>();

const handleTemplate = (templateId) => {
  emit("handle-template", templateId);
};

const emit = defineEmits(["handle-template"]);
</script>

<template>
  <v-card @click="handleTemplate(template.id)">
    <template v-slot:prepend>
      <img src="/src/assets/icons/document.svg" alt="Templates list" />
    </template>
    <v-card-title class="pt-0 pb-8">
      <span class="document-name">{{ template.name }}</span>
    </v-card-title>
  </v-card>
</template>

<style scoped lang="scss">
.document-name {
  font-size: 0.875rem;
  font-weight: 500;
}

.document-info,
.document-links {
  font-size: 0.688rem;
  font-weight: 500;
}
</style>
