<script setup>
import axios from "axios";
import Notification from "@/components/notifications/Notification.vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import ButtonLink from "@/components/partials/ButtonLink.vue";
import Page from "@/components/Page.vue";
import { useIsMobile } from "@/utils/checkMobile";

const isMobile = useIsMobile();

const unreadNotifications = computed(() => store.state.shared.unreadNotifications);

const page = ref(1);
const notifications = ref([]);

const notificationsCount = computed(() => notifications.value.length);
const router = useRouter();
const store = useStore();
const { t } = useI18n();

const filterUniqueNotifications = (notifications) =>
  Array.from(new Set(notifications.map((n) => n.id))).map((id) => {
    return notifications.find((n) => n.id === id);
  });

const getNotifications = async (e) => {
  const response = await axios.get("/api/user/notifications", {
    params: {
      page: page.value,
    },
  });

  const { data, current_page, last_page, unread_notification_count } = response.data;

  notifications.value = filterUniqueNotifications([
    ...response.data.data,
    ...toRaw(notifications.value),
  ]);

  store.commit("shared/updateUnreadNotifications", unread_notification_count);

  if (response.data.current_page === response.data.last_page) {
    e?.done("empty");
  } else {
    page.value++;
  }
};

const markNotificationsAsRead = async (items) => {
  const isSingleAction = items.length === 1;
  const url = `/api/user/notifications/read/${isSingleAction ? items[0].id : ""}`;

  try {
    await axios.post(url);
    notifications.value.forEach((item) => (item.read_at = new Date().toISOString()));
    const someUnread = notifications.value.some((n) => !n.read_at);
    if (!someUnread) {
      store.commit("shared/updateUnreadNotifications", 0);
    }
  } catch (error) {
    console.error(error);
  }
};

const deleteNotification = async (notificationId) => {
  try {
    await axios.delete(`/api/user/notifications/${notificationId}`);
    notifications.value = notifications.value.filter((n) => n.id !== notificationId);
  } catch (error) {
    console.error(error);
  }
};

const showNotification = async (notification) => {
  await markNotificationsAsRead([notification]);

  if (notification.url) {
    window.location.replace(notification.url);
  }
};

onMounted(async () => {
  await getNotifications();
});
</script>

<template>
  <Page :title="t('common.notifications')">
    <div>
      <ButtonLink
        v-if="unreadNotifications > 0 && notificationsCount > 0"
        @click="markNotificationsAsRead"
        class="mark-all-read-button"
      >
        {{ t("common.markAllAsRead") }}
      </ButtonLink>

      <v-list lines="three">
        <v-infinite-scroll
          :empty-text="t('common.youAreUpToDate')"
          :items="notifications"
          @load="getNotifications"
        >
          <v-list-item v-for="(notification, index) in notifications" :key="notification.id">
            <template v-slot:default>
              <Notification
                :notification="notification"
                @show="showNotification"
                @delete="deleteNotification"
              />

              <v-divider class="notifications-divider" v-if="notifications.length"></v-divider>
            </template>
          </v-list-item>
        </v-infinite-scroll>
      </v-list>
    </div>
  </Page>
</template>

<style scoped lang="scss">
.notifications-divider {
  margin-top: 15px;
  margin-bottom: 30px;
}

.v-list-item {
  margin-top: -16px !important;
  padding: 0 !important;
  padding-inline: 0 !important;
}

.v-navigation-drawer__scrim {
  z-index: 9999 !important;
}

.v-list {
  padding-top: 0 !important;
  overflow: auto !important;
  height: 82.18vh;
}

.mark-all-read-button {
  display: block;
  font-size: 0.875rem;
  text-align: right;
  width: 100%;
  padding-bottom: 20px;
}
</style>
