<script setup>
import { useI18n } from "vue-i18n";

import ActionIcons from "./ActionIcons.vue";

const { subscription } = defineProps(["subscription"]);
const emit = defineEmits(["showHistory"]);

const { t } = useI18n();
</script>
<template>
  <VRow dense>
    <VCol cols="12">
      <v-table>
        <tbody>
          <tr>
            <td>{{ t("billing.plan") }}</td>
            <td class="d-flex align-center justify-space-between">
              <div>{{ subscription?.plan ?? "Free" }}</div>

              <ActionIcons
                v-if="subscription"
                :subscription="subscription"
                @showHistory="emit('showHistory')"
                @cancelPlan="emit('cancelPlan')"
                @resumeSubscription="emit('resumeSubscription')"
              />
            </td>
          </tr>
          <tr>
            <td>{{ t("common.starts_at") }}</td>
            <td>{{ subscription?.from }}</td>
          </tr>
          <tr>
            <td>{{ t("billing.next_payment") }}</td>
            <td>{{ subscription?.status === "active" ? subscription?.to : "-" }}</td>
          </tr>
          <tr>
            <td>{{ t("common.ends_at") }}</td>
            <td>{{ subscription?.to }}</td>
          </tr>
        </tbody>
      </v-table>
    </VCol>
  </VRow>
</template>
<style scoped lang="scss">
.v-table {
  border: 1px solid var(--grey);
  border-right: none;
}

td {
  border-right: 1px solid var(--grey);
  height: 40px !important;
}
</style>
