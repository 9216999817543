import axios from "axios";
import { useToast } from "vue-toastification";
import useCookies from "@/utils/cookies";
import { userLogoutHandler } from "@/utils/userLogoutHandler";
import router from "@/router";

const cookies = useCookies();

axios.defaults.baseURL = import.meta.env.VITE_APP_API_BASE_URL;
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

axios.interceptors.request.use(
  (config) => {
    const apiToken = localStorage.getItem("apiToken") ?? cookies.get("apiToken");

    if (apiToken) {
      config.headers["Authorization"] = `Bearer ${apiToken}`;
    }

    config.headers["Accept-Language"] = localStorage.getItem("lang") ?? "en";

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    switch (error.response.status) {
      case 401:
        handle401Error();
        break;
      case 403:
        handle403Error();
        break;
      case 412:
        handle412Error();
        break;
      case 404:
        useToast().error("Resource not found.");
        break;
      case 500:
        useToast().error("Internal server error.");
        break;
      default:
        null;
    }

    return Promise.reject(error);
  },
);
export default axios;

const handle401Error = () => {
  userLogoutHandler();
};

const handle403Error = () => {
  useToast().error("This action is unauthorized.");
  router.push("/login");
};

const handle412Error = () => {
  useToast().error("Your session has expired. Please login again.");
};
