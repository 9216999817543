export default {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // Sprawdź, czy kliknięto poza elementem
      if (!(el === event.target || el.contains(event.target))) {
        // Wywołaj podaną funkcję
        binding.value(event, el);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
};
