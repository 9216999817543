import convertNode from "./convertNode";
import parseStyles from "../parseStyles";

export default function parseParagraph(xmlNode) {
  const startNewPage = xmlNode.getAttribute("startNewPage");
  const style = xmlNode.getAttribute("style");
  const className = xmlNode.getAttribute("class");

  const isHidden = className?.includes("hidden-paragraph");

  const attrs = {
    id: xmlNode.getAttribute("id"),
    contenteditable: !isHidden,
    class: className,
  };

  if (startNewPage === "true") {
    attrs.startNewPage = true;
  }

  if (style) {
    const { align, lineHeight } = parseStyles(style);

    align && (attrs.align = align);
    lineHeight && (attrs.lineHeight = lineHeight);
  }

  return {
    type: "paragraph",
    attrs,
    content: Array.from(xmlNode.childNodes).map(convertNode),
  };
}
