export default function updateMark(markType, attrs, state, dispatch) {
  const { from, to } = state.selection;
  let tr = state.tr;

  state.doc.nodesBetween(from, to, (node, pos) => {
    if (node.isText) {
      const existingMark = markType.isInSet(node.marks);
      const markRange = {
        from: Math.max(pos, from),
        to: Math.min(pos + node.nodeSize, to),
      };

      if (existingMark) {
        tr = tr.removeMark(markRange.from, markRange.to, markType);

        const newMark = markType.create({ ...existingMark.attrs, ...attrs });
        tr = tr.addMark(markRange.from, markRange.to, newMark);
      } else {
        const newMark = markType.create(attrs);
        tr = tr.addMark(markRange.from, markRange.to, newMark);
      }
    }
  });

  if (tr.docChanged) {
    dispatch(tr);
    return true;
  }
  return false;
}
