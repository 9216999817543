import {
  handleSelectParagraph,
  handleRemoveParagraphsSelection,
} from "./handlers/handleSelectParagraph";
import handleChangeVariable from "./handlers/handleChangeVariable";
import handleChangeDocument from "./handlers/handleChangeDocument";
import handleShowButtons from "./handlers/handleShowButtons";

interface ChatResponse {
  command: string;
  type?: "info" | "danger";
  ids?: string[];
  id?: string;
  new_value?: string;
  document?: string;
  paragraph_id?: string;
  arguments?: {};
}

export const useChatCommands = async (chatResponse: ChatResponse) => {
  switch (chatResponse.command) {
    case "change_variable":
      handleChangeVariable(chatResponse.arguments.id, chatResponse.arguments.new_value);
      break;
    case "go_to_step":
      break;
    case "change":
      handleChangeDocument(chatResponse.paragraph_id, chatResponse.document);
      break;
    case "select_paragraph":
      handleSelectParagraph(chatResponse.arguments.ids, chatResponse.arguments.type);
      break;
    case "red_flags":
      handleSelectParagraph(chatResponse.arguments.ids, chatResponse.arguments.type);
      break;
    case "remove_selection_from_paragraphs":
      handleRemoveParagraphsSelection();
      break;
    case "show_buttons":
      handleShowButtons(chatResponse.arguments.buttons);
      break;
    case "help_user":
      break;
    default:
      console.error("Command not found");
  }
};
