<script setup>
import PageHeader from "@/components/PageHeader.vue";
import { useIsMobile } from "@/utils/checkMobile";

const isMobile = useIsMobile();
const {
  title,
  withBackButton,
  withSpacing = false,
} = defineProps(["title", "withBackButton", "withSpacing"]);
</script>

<template>
  <div class="page">
    <PageHeader
      :title="title"
      :mobile="isMobile"
      :with-back-button="withBackButton"
      :with-spacing="withSpacing"
    >
      <template #title-actions>
        <slot name="title-actions" />
      </template>

      <template #back-button>
        <slot name="back-button" />
      </template>

      <template #buttons>
        <slot name="buttons" />
      </template>
    </PageHeader>
    <slot name="default" />

    <div class="footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 30px 30px 70px;

  @media (min-width: 768px) and (min-height: 900px) {
    padding: 30px;
  }

  @media (min-width: 768px) and (max-height: 900px) {
    padding: 15px;
  }

  .footer {
    margin-top: auto;
    margin-left: -30px;
    margin-right: -30px;

    @media (max-width: 769px) {
      max-width: 100vw;
      // width: calc(100% + 20px);
      // margin-left: -10px;
    }
  }
}
</style>
