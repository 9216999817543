import convertNode from "../convertNode";
import showNodeConditionally from "../../showNodeConditionally";

export default function parseListItem(xmlNode) {
  if (showNodeConditionally(xmlNode)) {
    const content = Array.from(xmlNode.children).map(convertNode);

    const isEmpty = !content || content.length === 0 || content.every((item) => !item);
    if (isEmpty) {
      return null;
    }
    return {
      type: "list_item",
      content,
      attrs: {
        id: xmlNode.getAttribute("id"),
        class: xmlNode.getAttribute("class"),
      },
    };
  } else {
    return null;
  }
}
