<script setup>
import { toggleMark } from "prosemirror-commands";
import defaultSchema from "@/components/ProseMirror/schemas/defaultSchema";
import checkMarkActive from "@/components/ProseMirror/menu/utils/checkMarkActive";
import { useI18n } from "vue-i18n";

import { fontFamilies, fontSizes } from "@/components/ProseMirror/menu/staticData";
import updateMark from "@/components/ProseMirror/menu/utils/updateMark";

const { editorView } = defineProps(["editorView"]);
const { t } = useI18n();
const schema = defaultSchema;

const marks = [
  {
    name: "strong",
    icon: "bold",
  },
  {
    name: "em",
    icon: "italic",
  },
  {
    name: "underline",
    icon: "underline",
  },
];

const localState = reactive({
  strong: false,
  em: false,
  underline: false,
  fontFamily: "",
  fontSize: "",
});

const handleChangeMark = async (type) => {
  const attrs = { [type]: localState[type] };

  if (type === "fontSize" && localState.fontSize === "14") {
    const mark = toggleMark(schema.marks[type], attrs);
    mark(editorView.state, editorView.dispatch);
  } else if (type === "fontFamily" && localState.fontFamily === "Archivo") {
    const mark = toggleMark(schema.marks[type], attrs);
    mark(editorView.state, editorView.dispatch);
  } else if (type === "fontSize" || type === "fontFamily") {
    updateMark(schema.marks[type], attrs, editorView.state, editorView.dispatch);
  } else {
    const mark = toggleMark(schema.marks[type], attrs);
    mark(editorView.state, editorView.dispatch);
  }

  editorView.focus();
};

const updateLocalState = (state) => {
  localState.strong = checkMarkActive(state, schema.marks.strong);
  localState.em = checkMarkActive(state, schema.marks.em);
  localState.underline = checkMarkActive(state, schema.marks.underline);

  const newFontSize = checkMarkActive(state, schema.marks.fontSize);
  localState.fontSize = newFontSize || "14";

  const newFontFamily = checkMarkActive(state, schema.marks.fontFamily);
  localState.fontFamily = newFontFamily || "Archivo";
};

onMounted(() => {
  updateLocalState(editorView.state);
});
</script>
<template>
  <div class="tab-content__items">
    <div class="tab-content__marks">
      <div
        v-for="mark in marks"
        :class="{
          active: localState[mark.name],
          'tab-content__item': true,
        }"
        @click="() => handleChangeMark(mark.name)"
      >
        <div
          class="mark__icon"
          :style="`mask: url(/images/icons/editor-toolbar/${mark.icon}.svg) no-repeat center`"
        ></div>
      </div>
    </div>

    <div class="tab-content__font">
      <v-combobox
        class="tab-content__font__size"
        v-model="localState.fontSize"
        :label="t('creator.toolbar.size')"
        variant="outlined"
        :items="fontSizes"
        density="compact"
        base-color="#8b8b8b"
        hide-details
        @update:model-value="() => handleChangeMark('fontSize')"
      ></v-combobox>
      <v-combobox
        class="tab-content__font__family"
        v-model="localState.fontFamily"
        :label="t('creator.toolbar.font')"
        variant="outlined"
        :items="fontFamilies"
        item-value="id"
        item-title="name"
        density="compact"
        base-color="#8b8b8b"
        hide-details
        :return-object="false"
        @update:model-value="() => handleChangeMark('fontFamily')"
      ></v-combobox>
    </div>
  </div>
</template>
<style scoped lang="scss">
.tab-content {
  &__items {
    height: 100%;
    padding: 0 15px;
    padding-bottom: 20px;

    .tab-content__marks {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 15px;
    }

    .tab-content__item {
      border: 1px solid var(--grey);
      padding: 0px 15px;
      font-size: 1.25rem;
      flex: 1;
      display: flex;
      justify-content: center;
      transition: 0.2s ease;
      &:hover {
        cursor: pointer;
        background-color: var(--light-grey);
      }

      .mark__icon {
        width: 30px;
        height: 30px;
        background-color: var(--dark-grey);
        transition: 0.3s ease;
      }

      &.active {
        .mark__icon {
          background-color: var(--blue-bonnet);
        }
      }
    }

    .tab-content__font {
      margin-top: 15px;
      display: flex;
      gap: 10px;

      &__size {
        max-width: 90px;
      }
    }
  }
}
</style>
