<script setup>
import { useRouter } from "vue-router";
import { useIsMobile } from "@/utils/checkMobile";

import ButtonLink from "@/components/partials/ButtonLink.vue";

const props = defineProps(["to", "marginTop", "marginBottom", "mobile"]);

const router = useRouter();
const isMobile = useIsMobile();

const redirect = () => {
  if (props.to === "") {
    return;
  }

  if (props.to) {
    router.push(props.to);
  } else {
    router.go(-1);
  }
};
</script>

<template>
  <div>
    <ButtonLink
      @click="redirect()"
      :class="`mt-${props.marginTop ?? '5'} mb-${props.marginBottom ?? '3'}`"
      class="back-button"
      variant="flat"
    >
      <img width="7" src="/src/assets/icons/arrow-back.svg" class="mr-2" alt="Back" />
      <span v-if="!isMobile"> {{ $t("common.back") }} </span>
    </ButtonLink>
  </div>
</template>

<style scoped lang="scss">
.back-button {
  text-decoration: none;
  color: #858788;
  font-size: 0.688rem;

  img {
    width: 5px;
  }

  &:hover {
    color: black;
  }
}
</style>
