<script setup>
import { checkSpacing, changeSpacing } from "@/components/ProseMirror/menu/utils/useSpacing";
import { lineHeights } from "@/components/ProseMirror/menu/staticData";
import { useI18n } from "vue-i18n";

const { editorView } = defineProps(["editorView"]);
const { t } = useI18n();
const localState = reactive({
  lineHeight: 100,
});

const handleChangeMark = async (type) => {
  changeSpacing(localState.lineHeight)(editorView.state, editorView.dispatch);

  editorView.focus();
};

const updateLocalState = (state) => {
  const newLineHeight = checkSpacing(state, "lineHeight");
  localState.lineHeight = newLineHeight || 100;
};

onMounted(() => {
  updateLocalState(editorView.state);
});
</script>
<template>
  <div class="tab-content__items">
    <div class="tab-content__marks">
      <v-combobox
        class="tab-content__font__family"
        v-model="localState.lineHeight"
        :label="`${t('creator.toolbar.line_height')} (%)`"
        variant="outlined"
        :items="lineHeights"
        density="compact"
        base-color="#8b8b8b"
        hide-details
        @update:model-value="() => handleChangeMark('lineHeight')"
      ></v-combobox>
    </div>
  </div>
</template>
<style scoped lang="scss">
.tab-content {
  &__items {
    height: 100%;
    padding: 0 15px;
    padding-bottom: 15px;

    .tab-content__marks {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 15px;
    }

    .tab-content__item {
      border: 1px solid var(--grey);
      padding: 0px 15px;
      font-size: 1.25rem;
      flex: 1;
      display: flex;
      justify-content: center;

      .mark__icon {
        width: 30px;
        height: 30px;
        background-color: var(--dark-grey);
      }

      &.active {
        .mark__icon {
          background-color: var(--blue-bonnet);
        }
      }
    }
  }
}
</style>
