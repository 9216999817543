<script setup>
import {onMounted, ref} from 'vue';
import axios from 'axios';
import {VBtn} from "vuetify/components";

const emit = defineEmits(['docusignConnected'])
const props = defineProps(['documentId']);
const isLoaded = ref(false);
const consoleUrl = ref('');

const isRedirecting = ref(false);

const redirectToDocusign = async () => {
  try {
    isRedirecting.value = true;

    const response = await axios.get('/api/docusign/auth-url');

    if (response.status === 200) {
      window.location.href = response.data.url;
    }
  } catch (error) {
    isRedirecting.value = false;
    console.error('Failed to redirect to DocuSign:', error);
  }
}

const uploadDocument = async (documentId) => {
  try {
    await axios.post(`/api/docusign/create-envelope`, {
      document: documentId,
    });
  } catch (error) {
    console.error('Failed to upload document to DocuSign:', error);
  }
}

onMounted(async () => {
  try {
    const url = props.documentId
      ? `/api/docusign/embed-ui?document=${props.documentId}`
      : '/api/docusign/embed-ui';

    const response = await axios.get(url);

    consoleUrl.value = response.data.console_view_url;

    emit('docusignConnected', true)
  } catch (error) {
    console.error('Failed to load DocuSign console:', error);
  }

  isLoaded.value = true;
});

watch(() => props.documentId, async () => {
  isLoaded.value = false;

  if(!props.documentId) {
    return;
  }

  try {
    await uploadDocument(props.documentId)
    const response = await axios.get(`/api/docusign/embed-ui?document=${props.documentId}`);

    consoleUrl.value = response.data.console_view_url;
  } catch (error) {
    console.error('Failed to load DocuSign console:', error);
  }

  isLoaded.value = true;
});
</script>

<template>
  <div>
    <template v-if="isLoaded">
      <template v-if="consoleUrl">
        <iframe :src="consoleUrl" width="100%" height="100%"></iframe>
      </template>
      <template v-else>
        <VBtn
          @click="redirectToDocusign"
          color="primary"
          variant="flat"
          :disabled="isRedirecting"
        >
          <template v-if="isRedirecting">
            <VProgressCircular size="sm" indeterminate color="black"></VProgressCircular>
          </template>
          {{ $t("docusign.connect_your_account") }}
        </VBtn>
      </template>
    </template>
  </div>
</template>

<style scoped>
iframe {
  display: block;
  background: #000;
  border: none;
  height: 100vh;
  width: 100%
}

.alert {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
