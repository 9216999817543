<script setup>
const { title, open } = defineProps(["title", "open"]);
const isOpen = ref(false);

onMounted(() => {
  isOpen.value = open;

  if (open) {
    const subscription = document.getElementById("subscription");
    subscription.scrollIntoView({ behavior: "smooth" });
  }
});
</script>

<template>
  <div id="subscription" class="dropdown">
    <div @click="isOpen = !isOpen" class="option">
      <h5>{{ title }}</h5>
      <img
        class="arrow"
        :class="isOpen ? 'rotate' : ''"
        src="/images/icons/arrow.svg"
        alt="Arrow right"
      />
    </div>
    <div class="content" v-if="isOpen">
      <slot />
    </div>
  </div>
</template>

<style scoped lang="scss">
.dropdown {
  margin-bottom: 57px;
}

.option {
  display: flex;
  align-items: center;
  text-decoration: none;
}

h5 {
  font-size: 0.875rem;
  line-height: 15px;
  font-weight: bold;
  color: black;
}

.arrow {
  margin-left: 15px;
  rotate: -90deg;
}

.rotate {
  transform: rotate(90deg);
}

.content {
  width: 100%;
  display: block;
  margin-top: 25px;
}
</style>
