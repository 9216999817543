<script setup>
import Form from "@/components/auth/Form.vue";
import { useToast } from "vue-toastification";
import axios from "axios";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
const errorMessage = ref("");
const clearForm = ref(false);
const $t = useI18n().t;
const router = useRouter();

const handleSubmitForm = async (formData) => {
  const token = router.currentRoute.value.query.token;

  const data = {
    ...formData,
    token,
  };

  try {
    const response = await axios.post("api/reset-password", data);

    if (response.status === 200) {
      useToast().success($t("notifications.password_reset_success"));
      errorMessage.value = "";
      clearForm.value = !clearForm.value;
      router.push("/login");
    } else {
      useToast().error($t("notifications.default_error"));
    }
  } catch (error) {
    errorMessage.value = error.response.data.message;
    useToast().error(errorMessage.value);
  }
};
</script>

<template>
  <div class="container">
    <img class="logo" src="/src/assets/images/logo.svg" alt="Legal torch logo" />
    <div class="form-container">
      <Form
        variant="Reset Password"
        :title="$t('auth.reset_password')"
        @submit="handleSubmitForm"
        :errorMessage="errorMessage"
        :clearForm="clearForm"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.container {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100%;

  .form-container {
    width: 100%;
    display: flex;
    justify-content: center;
    background: transparent url("@/assets/backgrounds/auth.png") 40% 100% no-repeat padding-box;
    background-size: 35%;
    padding-bottom: 150px;
  }

  .logo {
    width: 256px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media (max-width: 768px) {
  .form-container {
    width: 80% !important;
    background: none !important;
  }
  .container {
    background: transparent url("@/assets/backgrounds/auth.png") 0 100% no-repeat padding-box;
  }
}
</style>
