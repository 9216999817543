<script setup>
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import axios from "axios";
import { ref } from "vue";
import defaultSchema from "@/components/ProseMirror/schemas/defaultSchema";
import { EditorView } from "prosemirror-view";
import { EditorState } from "prosemirror-state";
import { Node } from "prosemirror-model";
import BackButton from "@/components/BackButton.vue";

const $t = useI18n().t;

const route = useRoute();
const documentId = ref(null);
const document = ref({
  name: "",
  content: "",
});

const documentPreviewRef = ref(null);
const mySchema = defaultSchema;
const signDocument = async () => {
  // TODO: Implement signing logic
};

let documentPreviewView = null;

const getDocument = async () => {
  try {
    const response = await axios.get(`api/documents/${documentId.value}`);
    document.value = response.data.data;
  } catch (error) {
    console.error(error);
  }
};

onMounted(async () => {
  documentId.value = route.params.id;
  await getDocument();

  documentPreviewView = new EditorView(documentPreviewRef.value, {
    state: EditorState.create({
      doc: Node.fromJSON(mySchema, toRaw(JSON.parse(document.value.content))),
    }),
    editable: () => false,
  });
});
</script>

<template>
  <div class="page">
    <div class="page-header">
      <h3>{{ $t("documents.signatures.sign_document") }}: {{ document.name }}</h3>
    </div>
    <BackButton />

    <v-btn width="200" class="v-btn-custom ml-auto mt-5 mr-15" variant="tonal">
      <template v-slot:prepend>
        <img src="/src/assets/icons/sign.svg" alt="Sign document" />
      </template>
      {{ $t("documents.signatures.sign") }}
    </v-btn>

    <div class="page-container">
      <div class="document-preview" ref="documentPreviewRef" id="documentPreview"></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.page {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;

  .page-header {
    display: flex;
    width: 100%;
    font-weight: bold;
    font-size: 1.5rem;

    h3 {
      border-image: linear-gradient(to left, var(--cambridge-blue), var(--booger-buster)) 30;
      border-width: 2px;
      border-style: solid;
      border-top: 0px;
      border-left: 0px;
      border-right: 0px;
      width: 100%;
      padding-bottom: 15px;
    }
  }

  .page-container {
    margin-top: 20px;

    .document-preview {
      padding: 25px;
      cursor: default;
    }
  }
}
</style>
