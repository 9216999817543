import { userLogoutHandler } from "@/utils/userLogoutHandler";
import { useToast } from "vue-toastification";
import i18n from "@/plugins/i18n";
import store from "@/store/store";

const isAuthRoute = () =>
  window.location.pathname === "/login" ||
  window.location.pathname === "/register" ||
  window.location.pathname === "/forgot-password" ||
  window.location.pathname === "/reset-password" ||
  window.location.pathname === "/verify-email";

const { t } = i18n.global;
const toast = useToast();

const timeToLogout = 1000 * 60 * 15; // 15 minutes
const timeToAlert = 1000 * 60 * 14; // 14 minutes (1 minute before logout)

export const sessionTimer = () => {
  let logoutTimer;
  let alertTimer;

  const startTimer = () => {
    if (
      !store.state.auth.isLoggedIn ||
      (store.state.auth.isLoggedIn && store.state.auth.user.isTemporary) ||
      isAuthRoute()
    ) {
      return;
    }

    logoutTimer = setTimeout(() => {
      userLogoutHandler();
    }, timeToLogout);

    alertTimer = setTimeout(() => {
      toast.warning(t("notifications.logout_warning"), {
        timeout: 10000,
      });
    }, timeToAlert);
  };

  const resetTimer = () => {
    clearTimeout(logoutTimer);
    clearTimeout(alertTimer);
    startTimer();
  };

  const events = ["click", "keypress"];

  events.forEach((event) => {
    window.addEventListener(event, resetTimer);
  });

  return {
    startTimer,
    resetTimer,
  };
};
