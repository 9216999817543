<script setup>
import PlansModalBase from "./PlansModalBase.vue";
import PlanCard from "./PlanCard.vue";

import { defineProps, onMounted, ref, defineEmits } from "vue";
import axios from "axios";
import { useI18n } from "vue-i18n";
import { useToast } from "vue-toastification";

const { tm } = useI18n();

const { open, plans, updateSubscriptionData } = defineProps([
  "open",
  "plans",
  "updateSubscriptionData",
]);
const emit = defineEmits(["close"]);

const isOpen = computed({
  get: () => open,
  set: (value) => {
    emit("close");
  },
});

const plansBenefits = computed(() => tm("billing.plans_benefits1"));
</script>
<template>
  <PlansModalBase :open="open" @close="emit('close')">
    <PlanCard
      v-for="(plan, index) in plans"
      :plan="plan"
      :plansBenefits="plansBenefits[index]"
      @closeModal="isOpen = false"
    />
  </PlansModalBase>
</template>
