import convertNode from "./convertNode";
import showNodeConditionally from "../showNodeConditionally";

export default function parseInlineContainer(xmlNode) {
  if (showNodeConditionally(xmlNode)) {
    if (xmlNode.children.length) {
      const content = [
        { type: "text", text: " " },
        ...Array.from(xmlNode.childNodes)
          .map(convertNode)
          .filter((n) => n),
        { type: "text", text: " " },
      ];
      return {
        type: "inlineContainer",
        content: content,
      };
    }
    return {
      type: "text",
      text: " " + xmlNode.textContent + " ",
    };
  } else {
    return null;
  }
}
