<template>
  <div class="loading-dots">
    <span></span>
    <span></span>
    <span></span>
  </div>
</template>

<style scoped lang="scss">
.loading-dots {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    display: block;
    width: 8px;
    height: 8px;
    margin: 0 4px;
    background-color: black;

    border-radius: 50%;
    animation: jump 0.6s infinite;
  }

  span:nth-child(2) {
    animation-delay: 0.2s;
  }

  span:nth-child(3) {
    animation-delay: 0.4s;
  }
}

@keyframes jump {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
</style>
