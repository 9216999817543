import { isString } from "vue-toastification/dist/types/ts/utils";
import { isObject } from "@vueuse/core";

const editorModule = {
  namespaced: true,
  state() {
    return {
      content: "",
      someChangesToSave: false,
      are_variables_set: false,
      availableVariables: [],
      editableVariable: {},
      variables: {},
      restoreVersion: null,
      documentId: null,
      templateId: null,
      wizard: {
        isCompleted: false,
        steps: [],
        answers: [],
        currentStep: null,
      },
      documentTitle: "New document",
    };
  },
  mutations: {
    updateContent(state, newContent) {
      state.content = newContent;
    },
    updateVariable(state, variable) {
      state.variables[variable.id] = variable.value;
    },
    setVariables(state, variables) {
      variables.forEach((variable) => {
        state.variables[variable.id] = variable.value;
      });
    },
    updateEditableVariable(state, variable) {
      state.editableVariable = variable;
    },
    updateDocumentId(state, documentId) {
      state.documentId = documentId;
    },
    setAvailableVariables(state, variables) {
      state.availableVariables = variables;
    },
    setWizardSteps(state, steps) {
      state.wizard.steps = steps;
    },
    setWizardAnswers(state, answers) {
      state.wizard.answers = answers;
    },
    setWizardCurrentStep(state, newStep) {
      state.wizard.currentStep = newStep;
    },
    setTemplateId(state, templateId) {
      state.templateId = templateId;
    },
    setRestoreVersion(state, version) {
      state.restoreVersion = version;
    },
    appendAvailableVariable(state, variable) {
      if (!state.availableVariables.find((v) => v.id === variable.id)) {
        state.availableVariables.push(variable);
      } else {
        // Update existing variable
        const index = state.availableVariables.findIndex((v) => v.id === variable.id);
        state.availableVariables[index] = variable;
      }
    },
    appendWizardStep(state, step) {
      state.wizard.steps.push(step);
    },
    updateWizardAnswer(state, answer) {
      const index = state.wizard.answers.findIndex((a) => a.questionId === answer.questionId);
      if (index === -1) {
        state.wizard.answers.push(answer);
      } else {
        state.wizard.answers[index] = answer;
      }
    },
    completeWizard(state) {
      state.wizard.isCompleted = true;
    },
    completeWizardVariables(state) {
      state.are_variables_set = true;
    },
    updateDocumentTitle(state, title) {
      state.documentTitle = title;
    },
    resetEditor(state) {
      state.content = "";
      state.variables = {};
      state.editableVariable = {};
      state.history = [];
      state.documentId = null;
      state.wizard = {
        steps: [],
        answers: [],
        isCompleted: false,
        currentStep: null,
      };
      state.documentTitle = "New document";
      state.someChangesToSave = false;
    },
    switchContentChangeStatus(state, value) {
      state.someChangesToSave = value;
    },
  },
  actions: {
    commitTransaction({ commit }, snapshot) {
      commit("updateContent", JSON.stringify(snapshot));
    },
  },
};

export default editorModule;
