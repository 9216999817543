<script setup>
import axios from "axios";
import { onMounted, ref, watch } from "vue";
import { useToast } from "vue-toastification";
import { useI18n } from "vue-i18n";
import algoliasearch from "algoliasearch/lite";
import instantsearch from "instantsearch.js";
import { searchBox, hits } from "instantsearch.js/es/widgets";
import DocumentInstruction from "@/views/app/mobile/DocumentInstruction.vue";
import CategoryListMobile from "@/views/app/mobile/CategoryListMobile.vue";
import router from "@/router";

// Environment variables
const appId = import.meta.env.VITE_ALGOLIA_APP_ID;
const searchKey = import.meta.env.VITE_ALGOLIA_SEARCH_KEY;

// Component emits
const emit = defineEmits(["handle-template"]);

// Composables
const toast = useToast();
const { t } = useI18n();

// State variables
const isLoading = ref(false);
const documentsList = ref([]);
const categoriesFromServer = ref([]);
const categoriesWithDocuments = ref([]);
const activeCategory = ref(null);
const searchQuery = ref("");

// Algolia initialization
const searchClient = algoliasearch(appId, searchKey);
const searchInstance = instantsearch({
  indexName: "templates",
  searchClient,
  initialUiState: {
    templates: {
      query: searchQuery.value,
    },
  },
});
// END

const addDocumentsToCategory = () => {
  categoriesWithDocuments.value = categoriesFromServer.value.map((category) => {
    const categoryDocuments = documentsList.value.filter(
      (document) => document.category_id === category.id,
    );

    return {
      ...category,
      children: categoryDocuments,
    };
  });
  isLoading.value = false;
};

const fetchCategoryList = async () => {
  try {
    isLoading.value = true;
    const response = await axios.get("/api/template-categories");
    categoriesFromServer.value = response.data.data;
  } catch (error) {
    toast.error(t("errors.search.no_categories"));
    isLoading.value = false;
  }
};

const handleOpenCategory = async (categoryId) => {
  await router.push({
    name: "mobile-template-category",
    params: { categoryId: categoryId },
  });
};

const handleClearSearchbox = () => {
  searchQuery.value = "";
};

onMounted(async () => {
  await fetchCategoryList();

  searchInstance.addWidgets([
    searchBox({
      container: "#searchbox",
    }),
    hits({
      container: document.createElement("div"),
      transformItems(items) {
        documentsList.value = items;
        return items;
      },
    }),
  ]);

  searchInstance.start();
});

// Watchers
watch(searchQuery, (newQuery) => {
  isLoading.value = true;
  searchInstance.helper.setQuery(newQuery).search();
});
watch(documentsList, () => {
  addDocumentsToCategory();
});
</script>

<template>
  <div class="search-bar">
    <v-text-field
      v-model="searchQuery"
      id="searchbox"
      variant="outlined"
      density="compact"
      :placeholder="$t('common.search')"
    >
      <template v-slot:append-inner>
        <img v-if="!searchQuery.length" src="/src/assets/icons/search.svg" alt="Search" />
        <img
          v-else
          class="clear-searchbox-btn"
          src="/src/assets/icons/x.svg"
          alt="Clear search"
          @click="handleClearSearchbox"
        />
      </template>
    </v-text-field>
  </div>

  <DocumentInstruction />

  <v-progress-circular v-if="isLoading" indeterminate></v-progress-circular>
  <CategoryListMobile
    v-else
    :categories="categoriesWithDocuments"
    :activeCategory="activeCategory"
    @openCategory="handleOpenCategory"
  />
</template>

<style scoped lang="scss">
#hits {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-height: 200px;

  .hit-name {
    padding-top: 10px;
    font-weight: bold;
  }

  .hit-description {
    padding-bottom: 5px;
    font-style: italic;
  }
}
.clear-searchbox-btn {
  padding: 10px;
  transform: translateX(5px);
  transition: 0.2s ease-in-out;
  &:hover {
    cursor: pointer;
    background-color: var(--grey);
  }
}

.search-bar {
  width: 100%;
}
</style>
