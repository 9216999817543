import parseParagraph from "./parseParagraph";
import parseContent from "./parseContent";
import parseHeader from "./parseHeader";
import parseBold from "./parseBold";
import parseText from "./parseText";
import parseVariable from "./parseVariable";
import parseDocument from "./parseDocument";
import parseList from "./lists/parseList";
import parseListItem from "./lists/parseListItem";
import parseItemContent from "./lists/parseItemContent";
import parseUnderline from "./parseUnderline";
import parseSwitch from "./alternatives/parseSwitch";
import parseValue from "./alternatives/parseValue";
import parseTable from "./tables/parseTable";
import parseTableRow from "./tables/parseTableRow";
import parseTableCell from "./tables/parseTableCell";
import parseDescriptionList from "./lists/parseDescriptionList";
import parseDefinitionTerm from "./lists/parseDefinitionTerm";
import parseDefinitionDetail from "./lists/parseDefinitionDetail";
import parseInlineContainer from "./parseInlineContainer";

export default function convertNode(xmlNode) {
  switch (xmlNode.nodeName) {
    case "document":
      return parseDocument(xmlNode);
    case "content":
      return parseContent(xmlNode);
    case "header":
      return parseHeader(xmlNode);
    case "paragraph":
      return parseParagraph(xmlNode);
    case "inline-container":
      return parseInlineContainer(xmlNode);
    case "bold":
      return parseBold(xmlNode);
    case "strong":
      return parseBold(xmlNode);
    case "underline":
      return parseUnderline(xmlNode);
    case "u":
      return parseUnderline(xmlNode);
    case "variable":
      return parseVariable(xmlNode);
    case "list":
      return parseList(xmlNode);
    case "item":
      return parseListItem(xmlNode);
    case "item-content":
      return parseItemContent(xmlNode);
    case "table":
      return parseTable(xmlNode);
    case "tr":
      return parseTableRow(xmlNode);
    case "td":
      return parseTableCell(xmlNode);
    case "dl":
      return parseDescriptionList(xmlNode);
    case "dt":
      return parseDefinitionTerm(xmlNode);
    case "dd":
      return parseDefinitionDetail(xmlNode);
    case "switch":
      return parseSwitch(xmlNode);
    case "value":
      return parseValue(xmlNode);
    default:
      return parseText(xmlNode);
  }
}
