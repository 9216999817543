const sharedModule = {
  namespaced: true,
  state() {
    return {
      isLoading: false,
      unreadNotifications: 0,
    };
  },
  mutations: {
    updateIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    updateUnreadNotifications(state, unreadNotifications) {
      state.unreadNotifications = unreadNotifications;
    },
  },
};

export default sharedModule;
