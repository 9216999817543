const scrollToElement = (element, params) => {
  if (element) {
    element.scrollIntoView({
      behavior: params?.behavior || "smooth",
      block: "start",
    });
  }
};

export default scrollToElement;
