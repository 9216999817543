import { ref, onMounted, onUnmounted } from "vue";

export function useIsMobile() {
  const isMobile = ref(window.innerWidth <= 768 || window.innerHeight <= 500);

  const updateIsMobile = () => {
    isMobile.value = window.innerWidth <= 768 || window.innerHeight <= 500;
  };

  onMounted(() => {
    window.addEventListener("resize", updateIsMobile);
  });

  onUnmounted(() => {
    window.removeEventListener("resize", updateIsMobile);
  });

  return isMobile.value;
}
