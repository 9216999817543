import convertNode from "./convertNode";

export default function parseContent(xmlNode) {
  return {
    type: "container",
    attrs: {
      id: crypto.randomUUID(),
    },
    content: Array.from(xmlNode.children)
      .map(convertNode)
      .filter((n) => n),
  };
}
