import { MenuItem } from "prosemirror-menu";

export function changeAlign(align) {
  return function (state, dispatch) {
    const { $from, $to } = state.selection;
    const attrs = { align };
    let tr = state.tr;
    state.doc.nodesBetween($from.pos, $to.pos, (node, pos) => {
      if (node.type === state.schema.nodes.paragraph) {
        tr = tr.setNodeMarkup(pos, null, { ...node.attrs, align });
      }
    });
    if (dispatch) dispatch(tr.scrollIntoView());
    return true;
  };
}

function alignItem(options) {
  const { title, icon, type } = options;
  return new MenuItem({
    title,
    icon,
    run: changeAlign(type),
    active(state) {
      const { from, to } = state.selection;
      let active = false;
      state.doc.nodesBetween(from, to, (node) => {
        if (node.attrs.align === type) active = true;
      });
      return active;
    },
  });
}

export default alignItem;
