enum ParagraphColor {
  info = "#8eff8e",
  danger = "#ff8e8e",
}

const applyStyle = (type, style, id?) => {
  const styleId = `dynamic-style-${type}${id ? `-${id}` : ""}`;
  let existingStyle = document.getElementById(styleId);

  if (existingStyle) {
    existingStyle.remove();
  }

  existingStyle = document.createElement("style");
  existingStyle.id = styleId;
  existingStyle.className = `dynamic-style-${type}`;
  existingStyle.innerHTML = style;
  document.head.appendChild(existingStyle);
};

export function removeStyles(type) {
  const styles = document.querySelectorAll(`.dynamic-style-${type}`);
  styles.forEach((style) => {
    style.remove();
  });
}

export function applyStyleToVariable(id) {
  const style = `
    .editor-container variable[data-id="${id}"] {
      background-color: yellow !important;
    }
  `;
  applyStyle("variable", style);
}

export function applyStyleToParagraph(id, type) {
  const style = `
    .editor-container p[data-id="${id}"] {
      background-color: ${ParagraphColor[type]} !important;
    }
  `;
  applyStyle("paragraph", style, id);
}
