import {
  setBlockType,
  toggleMark,
  joinUp,
  joinDown,
  lift,
  selectParentNode,
} from "prosemirror-commands";
import { wrapInList, splitListItem, liftListItem, sinkListItem } from "prosemirror-schema-list";
import { undo, redo } from "prosemirror-history";
import { undoInputRule } from "prosemirror-inputrules";

const mac = typeof navigator != "undefined" ? /Mac|iP(hone|[oa]d)/.test(navigator.platform) : false;

export default function useKeymap(schema) {
  const keys = {};

  function bind(key, command) {
    keys[key] = command;
  }

  bind("Mod-z", undo);
  bind("Shift-Mod-z", redo);
  bind("Backspace", undoInputRule);

  if (!mac) {
    bind("Mod-y", redo);
  }

  bind("Alt-ArrowUp", joinUp);
  bind("Alt-ArrowDown", joinDown);
  bind("Mod-BracketLeft", lift);
  bind("Escape", selectParentNode);

  // Marks ---------------------------------->
  const { strong, em } = schema.marks;

  bind("Mod-b", toggleMark(strong));
  bind("Mod-B", toggleMark(strong));

  bind("Mod-i", toggleMark(em));
  bind("Mod-I", toggleMark(em));

  // Nodes ---------------------------------->
  const { ordered_list, list_item, heading } = schema.nodes;

  bind("Shift-Ctrl-9", wrapInList(ordered_list));

  bind("Enter", splitListItem(list_item));
  bind("Mod-[", liftListItem(list_item));
  bind("Mod-]", sinkListItem(list_item));

  for (let i = 1; i <= 6; i++) {
    bind(`Shift-Ctrl-${i}`, setBlockType(heading, { level: i }));
  }

  return keys;
}
