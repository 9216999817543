import parseXmlToNodes from "./parseXmlToNodes";

function removeNbSp(str) {
  return str.replace(/&nbsp;/g, " ");
}

const parseXMLToJSON = (document) => {
  const contentData = removeNbSp(document);
  const itsVariable = /^\s*[\[{]/.test(contentData);
  const itsHTMLTag = /^\s*</.test(contentData);

  if (itsVariable) {
    try {
      return JSON.parse(contentData);
    } catch (error) {
      console.error("Error parsing JSON content", error);
    }
  } else if (itsHTMLTag) {
    try {
      return parseXmlToNodes(contentData);
    } catch (error) {
      console.error("Error parsing XML content", error);
    }
  } else {
    console.error("Unknown content format");
  }
};

export default parseXMLToJSON;
