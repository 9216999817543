<script setup>
import { useStore } from "vuex";
import Loader from "@/components/Loader.vue";

const store = useStore();
const isLoading = computed(() => store.state.shared.isLoading);
</script>
<template>
  <Loader v-if="isLoading" />
  <router-view />
</template>
