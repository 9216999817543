import ApproveDocument from "@/views/app/documents/ApproveDocument.vue";

const routes = [
  {
    path: "/",
    component: () => import("@/layouts/app/VisitorLayoutApp.vue"), //do not change to static imports - threatens to be cataclysmic on production
    children: [
      {
        path: "/documents/:uuid/approve",
        name: "document-approve",
        component: ApproveDocument,
        meta: { requiresAuthorization: false },
      },
    ],
  },
];

export default routes;
