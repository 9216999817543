<script setup>
import { useI18n } from "vue-i18n";

const { open } = defineProps(["open"]);
const emit = defineEmits(["close", "submit"]);

const { t } = useI18n();

const isOpen = computed({
  get: () => open,
  set: (value) => {
    emit("close");
  },
});
</script>
<template>
  <VDialog v-model="isOpen" max-width="400">
    <VCard class="text-center">
      <div class="close-container">
        <img @click="emit('close')" src="/images/icons/close.svg" alt="close dialog" />
      </div>
      <span class="dialog-title">
        {{ t("common.confirm_changes") }}
      </span>
      <div class="dialog-actions-container">
        <VBtn variant="elevated" class="form-button" @click="emit('submit')">
          {{ t("common.confirm") }}
        </VBtn>
      </div>
    </VCard>
  </VDialog>
</template>
<style scoped lang="scss">
.dialog-actions-container {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.dialog-title {
  display: block;
  width: 100%;
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--eerie-black);
  margin-top: 20px;
  margin-bottom: 40px;
}

.close-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-right: 20px;

  img {
    cursor: pointer;
  }
}

.form-button {
  font-size: 1.125rem;
  color: white !important;
  background-color: var(--cambridge-blue);
  text-transform: none;
  border-radius: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 145px;
  height: 45px;
}
</style>
