<script setup lang="ts">
import axios from "axios";
import DocumentNavigator from "@/components/documents/navigator/DocumentNavigator.vue";
import { useI18n } from "vue-i18n";
import { onMounted, ref } from "vue";
import { EditorView } from "prosemirror-view";
import { EditorState } from "prosemirror-state";
import { Node } from "prosemirror-model";
import defaultSchema from "@/components/ProseMirror/schemas/defaultSchema";
import { useRouter } from "vue-router";
import { useIsMobile } from "@/utils/checkMobile";
import { useStore } from "vuex";

const { documentId } = defineProps<{
  documentId: string;
}>();

const $t = useI18n().t;
const store = useStore();
const documentPreviewRef = ref(null);
const mySchema = defaultSchema;
const emit = defineEmits(["close"]);
const router = useRouter();

const isActive = ref(false);
const document = ref(null);
const isMobile = useIsMobile();

const handleOpenModal = () => {
  isActive.value = true;

  store.commit("shared/updateIsLoading", true);
};

const handleCloseModal = () => {
  isActive.value = false;
};

const onCloseModal = () => {
  document.value = null;
  if (router.currentRoute.value.name === "documents") {
    router.push(`/documents`);
  }
  emit("close");
};

let documentPreviewView = null;

const getDocument = async () => {
  try {
    const response = await axios.get(`api/documents/${documentId}`);
    document.value = response.data.data;
  } catch (error) {
    console.error(error);
  } finally {
    store.commit("shared/updateIsLoading", false);
  }
};

onMounted(async () => {
  handleOpenModal();
  await getDocument();

  try {
    JSON.parse(document.value.content);
    if (router.currentRoute.value.name === "documents") {
      router.push(`/documents/${documentId}`);
    }
  } catch (error) {
    return router.push(`/new-document-creator/${documentId}`);
  }

  documentPreviewView = new EditorView(documentPreviewRef.value, {
    state: EditorState.create({
      doc: Node.fromJSON(mySchema, toRaw(JSON.parse(document.value.content))),
    }),
    editable: () => false,
  });
});
</script>
<template>
  <v-dialog
    v-if="document"
    v-model="isActive"
    max-width="1200"
    @afterLeave="onCloseModal"
    scrollable
  >
    <v-card>
      <div v-if="!isMobile" class="close-container">
        <img @click="handleCloseModal" src="/images/icons/close.svg" alt="close dialog" />
      </div>
      <v-card-title class="dialog-title">
        {{ $t("documents.preview") }}
        <div class="d-flex ga-5">
          <DocumentNavigator
            v-if="isMobile"
            :document="document"
            :has-wizard-completed="true"
            :buttons="['edit', 'preview-share']"
          />
          <img
            v-if="isMobile"
            @click="handleCloseModal"
            src="/images/icons/close.svg"
            alt="close dialog"
          />
        </div>
      </v-card-title>
      <v-card-text>
        <div class="container">
          <DocumentNavigator
            v-if="!isMobile"
            class="ml-auto mr-0"
            :document="document"
            :has-wizard-completed="true"
            :buttons="['edit', 'preview-share']"
          />
          <div class="page-container">
            <div class="document-preview" ref="documentPreviewRef" id="documentPreview"></div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<style scoped lang="scss">
.v-card {
  overflow-y: hidden !important;
  max-height: calc(100% - 48px);
}

:deep(.v-card-text) {
  padding: 15px !important;
}

.dialog-title {
  font-size: 1.5rem;
  font-weight: bold;
  padding: 23px 23px 0px;

  @media (max-width: 768px) {
    padding: 12px;
    display: flex;
    margin-top: 20px;
    border-bottom: 1px solid var(--light-grey);
    justify-content: space-between;
  }
}

.close-container {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 20px;
  top: 20px;

  img {
    cursor: pointer;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .page-container {
    margin-top: 20px;

    .document-preview {
      padding: 25px;
      cursor: default;

      :deep(.ProseMirror .variable-node) {
        cursor: default;
      }

      @media (max-width: 768px) {
        padding: 0;
      }
    }
  }
}

.close-button {
  position: absolute;
  right: 0;
  top: 0;
}

@media (max-width: 768px) {
  :deep(.v-overlay__content) {
    min-width: 100% !important;
    max-width: 100% !important;
    margin-top: 100px;
    padding-bottom: 90px !important;
  }

  .v-card {
    border-radius: 0 !important;
  }

  :deep(.page-container li) {
    margin-left: 20px;
  }
}
</style>
