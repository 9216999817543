export const fontFamilies = [
  { id: "archivo", name: "Archivo" },
  { id: "arial", name: "Arial" },
  { id: "times-new-roman", name: "Times New Roman" },
  { id: "verdana", name: "Verdana" },
  { id: "georgia", name: "Georgia" },
  { id: "courier-new", name: "Courier New" },
];
export const fontSizes = [
  "8",
  "9",
  "10",
  "11",
  "12",
  "14",
  "16",
  "18",
  "24",
  "30",
  "36",
  "48",
  "60",
  "72",
  "96",
];

export const lineHeights = [50, 80, 100, 115, 150, 200, 300];
