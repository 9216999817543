import convertNode from "./nodes/convertNode";

export default function parseXmlToNodes(xml: string) {
  function removeNullNodes(obj) {
    if (Array.isArray(obj)) {
      return obj.map(removeNullNodes).filter((item) => item !== null);
    } else if (typeof obj === "object" && obj !== null) {
      const newObj = {};
      Object.keys(obj).forEach((key) => {
        newObj[key] = removeNullNodes(obj[key]);
      });
      return newObj;
    }
    return obj;
  }

  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xml, "text/xml");
  const doc = xmlDoc.documentElement;
  const meta = Array.from(doc.children).find((node) => node.nodeName === "meta");

  const content = Array.from(doc.children)
    .filter((node) => node.nodeName.toLowerCase() !== "meta")
    .map(convertNode);

  return {
    type: "doc",
    content: removeNullNodes(content),
  };
}
