import axios from "axios";
import store from "@/store/store";
export default async function setUserData() {
  await axios
    .get("/api/user")
    .then((response) => {
      const {
        id,
        name,
        email,
        is_temporary,
        is_email_verified,
        has_connected_docusign,
        disclaimer,
        has_valid_subscription,
      } = response.data.data;

      store.commit("auth/setUser", {
        id,
        name,
        email,
        isTemporary: is_temporary,
        isEmailVerified: is_email_verified,
        hasConnectedDocusign: has_connected_docusign,
        disclaimer,
        hasValidSubscription: has_valid_subscription,
      });

      return response.data.data;
    })
    .catch((error) => {
      store.commit("auth/setUser", {
        name: "",
      });
      console.error("Error fetching user data", error);
    });
}
